import React from 'react';
import Bottom from '../components/Layouts/Bottom';
import CreatePost from '../components/Post/CreatePost';
import { useNavigate } from "react-router-dom";
import CreateContentSidebar from '../components/Layouts/Sidebar/Content';

const CreateContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <CreateContentSidebar />
        </div>
        <div className="right-sec creat-right-sec col-md-6">
          <div className="full-box create-full-box">
            <div className="back-header">
              <ul className="list list-inline">
                <li className="back-btn">
                  <a
                    href="javascript:void(0)"
                    className="back-btn-a"
                    onClick={() => navigate(-1)}
                  >
                    <img src="/quickplay/images/arrow-small-right.svg" />
                    <span>Back</span>
                  </a>
                </li>
                <li className="common-heading">Create Content</li>
              </ul>
            </div>
            <CreatePost />
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default CreateContent;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const HeaderSearch = (props: any) => {
  const { redirect, searchData, searchValue }: any = props
  const navigate = useNavigate();

  const handleInputChange = (event: any) => {
    // setSearchValue(event.target.value);
      if (redirect)
        navigate("/search-filter");
  };


  return (
    <div className="full-box">
      <div className="page-search-box">

        <div className="input-box">
          <input type="text" name="search" value={searchValue} onChange={searchData} onClick={handleInputChange} placeholder="Search.." />
          <img
            src="./quickplay/images/search-icon.svg"
            className="search-info"
            alt=""
          />
        </div>

      </div>
    </div>
  );
};

export default HeaderSearch;

import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import Report from '../Feed/Report';
import FollowModal from '../common/FollowModal';
import { useNavigate, useParams } from 'react-router-dom';

function Profile() {
  const [userDetail, setUserDetail]:any = useState(null);
  const [userVideos, setUserVideos]:any = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [followModalType, setFollowModalType] = useState('followers');

  const navigate = useNavigate();
  const { userId }:any = useParams();

  const toggleReportModal = () => {
    setShowReportModal(state => !state);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const userModuleObj = new quickplay.QuickPlayUserModule();
        const userModuleResponse = await userModuleObj.getUserDetails(userId);
        const userFollowing = await userModuleObj.getUserFollowersStatus(
          userId,
        );
        if (userModuleResponse.status === 200) {
          let userInfo = userModuleResponse.data.result;
          // console.log(userInfo);
          setUserDetail(userInfo);
          setIsFollowing(userFollowing?.data?.result?.isFollowing);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [userId]);

  useEffect(() => {
    async function fetchVideos() {
      try {
        const userModuleObj = new quickplay.QuickPlayUserModule();
        var userVideoModuleResponse = await userModuleObj.getUserVideoList({
          userId: userId,
        });
        if (userVideoModuleResponse.status === 200) {
          let videoList = userVideoModuleResponse.data.result;
          // console.log(videoList);
          setUserVideos(videoList);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchVideos();
  }, [userId]);

  if (!userVideos && !userDetail) {
    return <p>Loading...</p>;
  }

  const handleContentDetail = (contentInfo:any) => {
    navigate(`/content/${contentInfo.videoId}`);
  };

  const handleFollowEvent = async (event:any, userId:any) => {
    const userModuleObj = new quickplay.QuickPlayUserModule();
    var formData = {
      followingId: userId,
      isFollow: !isFollowing,
    };
    const userModuleResponse = await userModuleObj.followUser(formData);
    if (
      userModuleResponse.status === 201 ||
      userModuleResponse.status === 200
    ) {
      if (formData.isFollow) userDetail.followersCount++;
      else userDetail.followersCount--;
      let followingList:any = localStorage.getItem('following');
      if (
        followingList != undefined &&
        followingList != null &&
        followingList != 'undefined'
      ) {
        followingList = JSON.parse(followingList);
        followingList.push(userId);
        localStorage.setItem('following', JSON.stringify(followingList));
        setIsFollowing(!isFollowing);
      }
    }
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="full-box profile-full-box userprofile">
      <div className="back-btn">
            <a href="javascript:void(0)" className="back-btn-a" onClick={goBack}>
              <img src="/quickplay/images/arrow-small-right.svg" />
              <span>Back</span></a>
          </div>
      <div className="profile-page-head">
        <ul className="profile-page-head-ul list-none">
          <li className="profile-page-head-avatar">
            <div className="img-sec">
              <img
                src={userDetail?.profileImageUrl || '/quickplay/images/Profile.png'}
                id="profileImage"
                alt="profile-image"
                className="bg-img-02 profileImage"
              />
            </div>
            <div className="profile-follow">
              {!isFollowing && (
                <button
                  className="follow-profile"
                  id={`follow-${userDetail?.userId}`}
                  onClick={e => handleFollowEvent(e, userDetail?.userId)}
                >
                  Follow +
                </button>
              )}
              {isFollowing == true && (
                <button
                  className="follow-profile"
                  id={`unfollow-${userDetail?.userId}`}
                  onClick={e => handleFollowEvent(e, userDetail?.userId)}
                >
                  Unfollow
                </button>
              )}
            </div>
          </li>

          <li className="profile-page-head-content">
            <ul className="profile-page-head-content-inner">
              <li>
                <h4 id="displayName">{userDetail?.fullName}</h4>
                <h5 id="userId">{userDetail?.userName}</h5>
              </li>

              <li>
                <button
                  onClick={() => {
                    setShowFollowModal(true);
                    setFollowModalType('followers');
                  }}
                >
                  Followers
                </button>
                <span id="followerCount">{userDetail?.followersCount}</span>
              </li>

              <li>
                <button
                  onClick={() => {
                    setShowFollowModal(true);
                    setFollowModalType('following');
                  }}
                >
                  Following
                </button>
                <span id="followingCount">{userDetail?.followingCount}</span>
              </li>
              <li>
                <button>Posts</button>
                <span id="videoCount">{userDetail?.videoCount}</span>
              </li>
            </ul>
            <div className="c-text text-blk mt-t-15" id="userDescription">
              {userDetail?.description}
            </div>
          </li>
        </ul>
      </div>
      <FollowModal
        show={showFollowModal}
        onHide={() => setShowFollowModal(false)}
        type={followModalType}
        userData={userDetail}
      />

      <div className="inner-box arrival-box profile-videos">
        <div id="tabs-content">
          <div className="row tab-content userVideoData" id="tab1">
            {userVideos.map((video:any) => {
              let thumbnailUrls = video.thumbnailUrls
                ? video.thumbnailUrls[0]
                : video.thumbnailUrl;
                if (video.contentType == 'video') {
                  return (
                    <div
                      className="col-md-3 col-sm-3 col-xs-6 box text-center"
                      key={video.videoId}
                    >
                      <div
                        className="img-box open-video-detail"
                        id={video.videoId}
                        style={{
                          background: `url(${thumbnailUrls}) center`,
                          height: '227px',
                          maxHeight: '227px',
                          borderRadius: '9px',
                          backgroundSize: 'cover',
                        }}
                        onClick={() => handleContentDetail(video)}
                      >
                        <span className="av-icon">
                          <img src="../quickplay/images/folder-icon.svg" />
                        </span>
                      </div>
                    </div>
                );
                }
                else {
                  return (
                    <div className="col-md-3 col-sm-3 col-xs-6 box text-center">
                      <div className="img-box open-video-detail text-wrapper" style={{
                        background: '#fff',
                        maxHeight: '227px',
                        height: '227px',
                        borderRadius: '9px',
                      }} id={video.videoId}>
                        <span className="av-icon"><img src="../quickplay/images/Text.svg" /></span><p>{video?.description}</p>
                      </div>
                    </div>
                  )
                }
            })}
          </div>
        </div>
      </div>
      {showReportModal && (
        <Report
          show={showReportModal}
          onHide={() => toggleReportModal()}
          type="user"
          payloadData={{ userId }}
        />
      )}
    </div>
  );
}

export default Profile;

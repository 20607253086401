import React from 'react';
import Sidebar from '../components/Layouts/Sidebar';
import Bottom from '../components/Layouts/Bottom';
import MyProfile from '../components/Profile/MyProfile';

const MyProfilePage = () => {
  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar showProfile={false}/>
        </div>
        <div className="right-sec col-md-9">
          <MyProfile />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default MyProfilePage;


import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteAccountModal = (props:any) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} id="signupmodel">
      <div className="modal-content text-center">
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <div className="modal-body">
            <p>Are you sure you want to delete your account?</p>
            <div className="bottom-btn">
              <button className="btn btn-danger sure-btn" onClick={props.confirmDelete}>Yes,Sure!</button>
              <button className="btn btn-secondary cancel-btn" style={{"marginLeft": "5px"}} onClick={props.handleClose}>Cancel</button>
            </div>
          </div>
        </div>
    </Modal>
  );
}


export default DeleteAccountModal;
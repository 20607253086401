import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import { useNavigate } from 'react-router-dom';

function CreatePost() {
  const [displayLoader, setDisplayLoader] = useState('none');
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data:any) => {
        // handle success case
        if (data) {
          setIsLoggedin(true);
        }
      })
      .catch((error:any) => {
        // handle error case
        console.error(error);
      });
  }, []);

  const handleFileUploadChange = async (event:any) => {
    const fileUploaded = event.target.files[0];
    console.log('fileUploaded', fileUploaded);
    readFile(fileUploaded);
  };

  const readFile = (inputFile:any) => {
    if (inputFile) {
      console.log('inputFile', inputFile);
      setDisplayLoader('block');
      if (inputFile.type != 'video/mp4' && inputFile.type != 'video/mpeg') {
        return false;
      }
      const reader = new FileReader();
      reader.onload = async function () {
        let signedUrlResponse = await getSignedUrl();
        if (signedUrlResponse.status == 200) {
          let signedUrl = signedUrlResponse.data.result.uploadUrl;

          let videoUrl = signedUrlResponse.data.result.url;
          var file = inputFile;
          var requestOptions:any = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
          };
          fetch(signedUrl, requestOptions)
            .then(response => {
              setDisplayLoader('none');
              console.log('RESPONSE === ', response);
              let finalVideoUrlList = [];
              finalVideoUrlList.push(videoUrl);
              sessionStorage.setItem(
                'videoUrl',
                JSON.stringify(finalVideoUrlList),
              );
              //navigate to  page 2
              navigate('/create-post-page-02');
            })
            .then(result => {
              console.log('RESULT', result);
              setDisplayLoader('none');
            })
            .catch(error => {
              console.log('error === ', error);
              setDisplayLoader('none');
            });
        } else {
          console.log('signedUrlResponse', signedUrlResponse);
          if (signedUrlResponse.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            // navigate to Signin
            navigate('/sign-in');
          }
        }
      };

      reader.readAsDataURL(inputFile);
    }
  };

  const getSignedUrl = async () => {
    var feedModuleObj = new quickplay.QuickPlayFeedModule();
    let feedModuleResponse = await feedModuleObj.getSignedUrl({
      type: 'video',
    });
    return feedModuleResponse;
  };

  return (
    <>
      <div className="creat-full mt-t-100">
        <div className="creat-box text-center">
          <img src="/quickplay/images/no-data.png" alt="no-data" />
          <div className="rel-pos">
            <div
              className="section-loader image-upload-loader"
              style={{
                display: `${displayLoader}`,
              }}
            >
              <div className="preloader" id="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <form action="" method="POST" encType="multipart/form-data">
            <div className="content-sec dropzone-wrapper">
              <div className="c-text text-g">
                Drag and Drop Your Best Content...!
              </div>
              <div className="sml-text mt-t-10">
                Supported file format MP4, MOV,
                <br />
                and AVI max video size 5MB
              </div>

              <div className="file-upload-btn-parent">
                <input
                  type="file"
                  name=""
                  className="file-upload-btn-input dropzone"
                  accept=".mp4,.mpeg,.avi"
                  onChange={handleFileUploadChange}
                />
                <a href="" className="new-custom-btn-b mt-t-30 file-upload-btn">
                  Select from computer
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreatePost;

import React, { useEffect, useState } from "react";
import HeaderSearch from "../components/Layouts/HeaderSearch";
import Sidebar from "../components/Layouts/Sidebar";
import Bottom from "../components/Layouts/Bottom";
import { SEARCH_TABS } from "../constants";
import UserRail from "../components/UserRail/UserRail";
import HashtagRail from "../components/HashtagRail/HashtagRail";
import VideoRail from "../components/VideoRail/VideoRail";
import quickplay from "quickplay";
import debounce from 'lodash/debounce';


const SearchFilter = () => {
  const [activeTab, setActiveTab] = useState(SEARCH_TABS.ALL);
  const [userData, setUserData] = useState(null);
  const [videodata, setVideoData] = useState(null)
  const [hashtagData, setHashtagData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [userDataCheck, setUserDataCheck] = useState(true);
  const [videoDataCheck, setVideoDataCheck] = useState(true);
  const [hagDataCheck, setHagDataCheck] = useState(true);
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if(tab === "All")
    window.scrollTo(0,0)
  };
  useEffect(() => {
    search("");
  }, [])

  const search = async (searchKeyword: any) => {
    var discoverModuleObj = new quickplay.QuickPlayDiscover();
    var topProfileData = await discoverModuleObj.getDiscoverTopProfiles({
      search: searchKeyword,
    });
    let users: any = { railName: "Profiles" };
    users.itemList = topProfileData.data.result.map((profile: any) => {
      return { profile };
    });
    if (users.itemList.length == 0) {
      setUserDataCheck(false);
    }
    else if (users.itemList.length != 0) {
      setUserDataCheck(true);
    }
    setUserData(users);
    var allVideos = await discoverModuleObj.getDiscoverAllVideos({
      search: searchKeyword,
      limit: 10,
      offset: 1,
    });
    let videos: any = { railName: "Videos" };
    videos.itemList = allVideos.data.result.videos;
    if (videos.itemList.length == 0) {
      setVideoDataCheck(false);
    }
    else if (videos.itemList.length != 0) {
      setVideoDataCheck(true);
    }

    setVideoData(videos);
    let hashtags: any = { railName: "Hashtags" };
    hashtags.itemList = allVideos.data.result.hashtags.map((hashtag: any) => {
      return { hashtag };
    });
    if (hashtags.itemList.length == 0) {
      setHagDataCheck(false);
    }
    else if (hashtags.itemList.length != 0) {
      setHagDataCheck(true);
    }
    setHashtagData(hashtags);
  }

  const searchData = async (event: any) => {
    // setSearchValue(event.target.value);
    let searchKeyword = event.target.value;
    setSearchValue(searchKeyword);
    debouncedHandleInput(searchKeyword);
  };
  const debouncedHandleInput = debounce(async (value) => {
    await search(value);
  }, 500);

  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar showProfile={false} />
        </div>
        <div className="right-sec col-md-9 profile-content-list">
          <HeaderSearch redirect={false} searchData={searchData} />
          <div className="tabs-section">
            <ul id="tabs-nav">
              <li
                onClick={() => handleTabClick(SEARCH_TABS.ALL)}
                className={activeTab === SEARCH_TABS.ALL ? "active" : ""}
              >
                <a href="#tab1">All</a>
              </li>
              {(userDataCheck) && <li
                onClick={() => handleTabClick(SEARCH_TABS.PROFILES)}
                className={activeTab === SEARCH_TABS.PROFILES ? "active" : ""}
              >
                <a href="#tab2">Profiles</a>
              </li>}
              {(hagDataCheck) && <li
                onClick={() => handleTabClick(SEARCH_TABS.HASHTAGS)}
                className={activeTab === SEARCH_TABS.HASHTAGS ? "active" : ""}
              >
                <a href="#tab3">Hashtags</a>
              </li>}
              {(videoDataCheck) && <li
                onClick={() => handleTabClick(SEARCH_TABS.VIDEOS)}
                className={activeTab === SEARCH_TABS.VIDEOS ? "active" : ""}
              >
                <a href="#tab5">Videos</a>
              </li>}
            </ul>
          </div>
          <div id="tabs-container">
            {activeTab === SEARCH_TABS.ALL && (
              <div id="tab1">
                <h1>All</h1>
                {(userDataCheck) && <UserRail data={userData} />}
                {(hagDataCheck) && <HashtagRail data={hashtagData} />}
                {(videoDataCheck) && <VideoRail data={videodata} />}
              </div>
            )}
            {activeTab === SEARCH_TABS.PROFILES && (
              <div id="tab2">
                <h1>Profiles</h1>
                <UserRail data={userData} />
              </div>
            )}
            {activeTab === SEARCH_TABS.HASHTAGS && (
              <div id="tab3">
                <h1>Hashtags</h1>
                <HashtagRail data={hashtagData} />
              </div>
            )}
            {activeTab === SEARCH_TABS.VIDEOS && (
              <div id="tab5">
                <h1>Videos</h1>
                <VideoRail data={videodata} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default SearchFilter;

import React from 'react';
import './App.css';
import './assets/css/custom.css'
import quickplay from 'quickplay';
import './assets/css/responsive.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Test from './Test';
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Discover from "./pages/Discover";
import Profile from "./pages/Profile";
import MyProfile from "./pages/MyProfile";
import ActivityTimeline from "./pages/ActivityTimeline";
import Notification from "./pages/Notification";
import CreateContent from "./pages/CreatePost";
import CreateContentStepTwo from "./pages/CreatePostStepTwp";
import FeedDetail from "./pages/FeedDetail";
import HashtagDetail from "./pages/HashtagDetail";
import CreateTextContent from './pages/CreateText';
import SearchFilter from './pages/searchFilter';
import ForgotPasswordForm from './components/Auth/ForgotPasswordForm';

function App() {
  let quickPlaySDKInitilize = new quickplay.QuickPlaySDKInitilize();
  quickPlaySDKInitilize.initialize({
    apiKey: "5bdd28f0-a425-4884-be50-9bf5fa35e319",
    secretKey: "33728ccd-9dec-4bfe-8a66-0c489a1fc8f2",
  });
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="" element={<Home />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/sign-in" element={<Login />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/activity-timeline" element={<ActivityTimeline />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/create-post" element={<CreateContent />} />
          <Route path="/create-post-page-02" element={<CreateContentStepTwo />} />
          <Route path="/content/:contentId" element={<FeedDetail />} />
          <Route path="/hashtag-detail" element={<HashtagDetail />} />
          <Route path="/create-text" element={<CreateTextContent />} />
          <Route path="/search-filter" element={<SearchFilter />} />
          <Route path='/forgot-password' element={<ForgotPasswordForm />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';

function VideoRail(props: any) {
    const navigate = useNavigate();
    const { data }: any = props;
    const [owlOptions, setOwlOptions]: any = useState(null);
    const [isClient, setIsClient] = useState(true)
    console.log(data);
    useEffect(() => {

        if (data) {
            let owlCarouselOptions = {
                loop: true,
                items: 4,
                autoplay: true,
                autoplayTimeout: 5000,
                video: true,
                autoheight: "true",
                autoplaySpeed: 1000,
                margin: 0,
                autoplayHoverPause: true,
                nav: true,
                navText: [
                    '<img src="./quickplay/images/arrow-small-right.svg">',
                    '<img src="./quickplay/images/arrow-small-left-g.svg">',
                ],
                dots: false,
                responsive: {
                    0: {
                        items: 2.5,
                        nav: false,
                    },
                    600: {
                        items: 4.5,
                        nav: false,
                    },
                    1000: {
                        items: 4,
                        loop: false,
                    },
                }
            };
            setOwlOptions(owlCarouselOptions);
        }
    }, [data]);

    if (data && data.length === 0) {
        return <p></p>;
    }

    if (!data) {
        return <p>Loading...</p>;
    }


    const handleContentDetail = (contentInfo: any) => {
        if(contentInfo.id)
        {
            navigate(`/content/${contentInfo.id}`);
        }
        else if(contentInfo._id){
            navigate(`/content/${contentInfo._id}`);
        }
    }

    return (

        <div className="inner-box arrival-box mt-t-25">
            <div className='rail-header'>
                <h4>{data.railName}</h4>
                {/* <a href='#'><span>See All</span><img src='./quickplay/images/arrow-small-left-g.svg'></img></a> */}
            </div>
            {/* <OwlCarousel  {...owlOptions} className="owl-carousel owl-theme">
                {
                    data.itemList.map((videoInfo: any) => (
                        <div className="box text-center" style={{ "width": "140px !important" }} key={videoInfo.id}>
                            <div className="img-box open-video-detail" id={videoInfo.id}
                                style={{
                                    "background": `url("${videoInfo.thumbnail || videoInfo.thumbnailUrl}") center`,
                                    "height": "150px !important",
                                    "backgroundSize": "cover",
                                    "borderRadius": "9px"
                                }} onClick={() => handleContentDetail(videoInfo)}>
                                <span className="av-icon" >
                                    <img alt='content icon' src="./quickplay/images/folder-icon.svg" />
                                </span>
                            </div>
                        </div>
                    ))
                }
            </OwlCarousel> */}
            <OwlCarousel  {...owlOptions} className="owl-carousel owl-theme">
                {
                    data.itemList.map((videoInfo: any) => {
                        if (videoInfo.contentType == 'text') {
                            return (
                                <div className="col-md-12 col-sm-12 col-xs-12 box text-center">
                                  <div className="img-box open-video-detail text-wrapper" style={{
                                    background: '#fff',
                                    maxHeight: '227px',
                                    height: '227px',
                                    borderRadius: '9px',
                                  }} id={videoInfo.videoId}>
                                    <span className="av-icon"><img src="./quickplay/images/Text.svg" /></span><p>{videoInfo?.description}</p>
                                  </div>
                                </div>
                              )
                        }
                        else {
                            return (
                                <div className="box text-center" style={{ "width": "140px !important" }} key={videoInfo.id}>
                                    <div className="img-box open-video-detail" id={videoInfo.id}
                                        style={{
                                            "background": `url("${videoInfo.thumbnail || videoInfo.thumbnailUrl}") center`,
                                            "height": "150px !important",
                                            "backgroundSize": "cover",
                                            "borderRadius": "9px"
                                        }} onClick={() => handleContentDetail(videoInfo)}>
                                        <span className="av-icon" >
                                            <img alt='content icon' src="./quickplay/images/folder-icon.svg" />
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </OwlCarousel>
        </div>
    );

}


export default VideoRail;
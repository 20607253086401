import React, { HTMLProps, useEffect, useState } from 'react';
import quickplay from "quickplay";
import { useNavigate } from 'react-router-dom';

interface index extends HTMLProps<HTMLSpanElement> {
  index: number;
}

function CreatePostStepTwo() {
  const [displayLoader, setDisplayLoader] = useState('none');
  const [errors, setErrors]:any = useState({});
  const [success, setSuccess]:any = useState({});
  const [currentVideoList, setCurrentVideoList]:any = useState([]);
  const [categoryOptions, setCategoryOptions]:any = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    visibility: 'public',
    follow: true,
    type: "camera-upload",
    "categoryId": '',
    "categoryName": '',
    "contentUrl": [],
    s3Url: ""
  });

  useEffect(() => {
    let storedVideos = sessionStorage.getItem("videoUrl");
    if(storedVideos == "" || storedVideos == undefined || storedVideos == null) {
      navigate('/create-post');
      return;
    }
    let videoList = JSON.parse(storedVideos);
    setCurrentVideoList(videoList);
    setFormData((prevFormData:any) => ({ ...prevFormData, ['contentUrl']: { "type": "video","urls": videoList } }));
    formData.s3Url = videoList[0];
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const feedModuleObj = new quickplay.QuickPlayFeedModule;	
        let feedModuleResponse = await feedModuleObj.getCategoryList({search: "",limit:100, offset:1});
        if(feedModuleResponse.status == 200){
          let categoryList = feedModuleResponse.data.result;
          if(categoryList && categoryList.length){
            setCategoryOptions(categoryList);
          }
        }   
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategories();
  }, []);

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleCategoryChange = (event:any)=>{
    setFormData((prevFormData) => ({ ...prevFormData, ['categoryId']: event.target.value }));
  }

  const handleFileUploadChange = async (event:any) => {
    const fileUploaded = event.target.files[0];
    console.log("fileUploaded", fileUploaded);
    readFile(fileUploaded);
  };

  const readFile = (inputFile:any)=>{
    if (inputFile) {
      console.log("inputFile", inputFile);
      setDisplayLoader("block")
      if (inputFile.type != "video/mp4" && inputFile.type != "video/mpeg") {
        return false;
      }
      const reader = new FileReader();
      reader.onload = async function (e) {
        let signedUrlResponse = await getSignedUrl();
        if (signedUrlResponse.status == 200) {
          let signedUrl = signedUrlResponse.data.result.uploadUrl;

          let videoUrl = signedUrlResponse.data.result.url;
          var file = inputFile;
          var requestOptions:any = {
            method: "PUT",
            body: file,
            redirect: "follow",
          };
          fetch(signedUrl, requestOptions)
            .then((response) => {
              setDisplayLoader("none");
              console.log("RESPONE === ", response);
              const newVideoList:any = [...currentVideoList, videoUrl];
              sessionStorage.setItem(
                "videoUrl",
                JSON.stringify(newVideoList)
              );
              //update the State of currentVideoList
              setCurrentVideoList(newVideoList);
              setFormData((prevFormData:any) => ({ ...prevFormData, ['contentUrl']: { "type": "video","urls": newVideoList } }));
            })
            .then((result) => {
              console.log("RESULT", result);
              setDisplayLoader("none");
            })
            .catch((error) => {
              console.log("error === ", error);
              setDisplayLoader("none");
            });
        } else {
          console.log("signedUrlResponse", signedUrlResponse);
          if (signedUrlResponse.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            // navigate to Signin
            navigate('/sign-in');
          }
        }
      };

      reader.readAsDataURL(inputFile);
    }
  }

  const getSignedUrl = async()=>{
    var feedModuleObj = new quickplay.QuickPlayFeedModule();
    let feedModuleResponse = await feedModuleObj.getSignedUrl({
      type: "video",
    });
    return feedModuleResponse;
  }

  const validateForm = () => {
    const newErrors:any = {};

    if(currentVideoList.length==0){
      alert("Please select media content");
      newErrors.contentVideo = 'Please select media content'
    }

    if (!formData.title) {
      newErrors.title = 'Please enter the title';
    }

    if (!formData.description) {
      newErrors.description = 'Please enter the description';
    }

    if (formData.categoryId === '') {
      newErrors.category ='Please select a category.';
    }

    if (!isTermsChecked) {
      newErrors.terms ='Please check term and conditions.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handlePostSubmit = async (event:any) => {
    //Prevent page reload
    event.preventDefault();
    if (validateForm()) {
      const selectedOptionObject:any = categoryOptions.find((option:any) => option.categoryId === formData.categoryId);
      const selectedOptionText = selectedOptionObject ? selectedOptionObject.categoryName : '';
      formData.categoryName = selectedOptionText;
      
      console.log("formData", formData);
      setDisplayLoader("block");
      const feedModuleObj = new quickplay.QuickPlayFeedModule;	
      let feedModuleResponse = await feedModuleObj.uploadContent(formData);
      console.log("feedModuleResponse ==== ", feedModuleResponse);
      if(feedModuleResponse.status == 200 || feedModuleResponse.status == 201) {
        setDisplayLoader("none");
        sessionStorage.removeItem("videoUrl");
        setSuccess({"sucessMessage": feedModuleResponse.data.statusMessage });
        setErrors({});
        //navigate to  Home Page
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
      else {
        setDisplayLoader("none");
        setSuccess({});
        setErrors({errorMessage: feedModuleResponse?.data?.statusMessage});
      }
      
    }
  };

  const removeImageItem = (ind:any)=>{
    // Create a copy of the items array
    const updatedVideoList = [...currentVideoList];
    // Remove the item at the specified index
    updatedVideoList.splice(ind, 1);
    
    // Update the state with the new array of items
    setCurrentVideoList(updatedVideoList);
    setFormData((prevFormData:any) => ({ ...prevFormData, ['contentUrl']: { "type": "video","urls": updatedVideoList } }));
  }

  return (
    <>
      <div className="full-box create-full-box">
          <div className="back-header">
            <ul className="list list-inline">
              <li className="back-btn">
                <a href="/create-post" className="back-btn-a">
                  <img src="/quickplay/images/arrow-small-right.svg" /><span>Back</span>
                  </a>
              </li>
              <li className="common-heading">Create Post</li>
            </ul>
          </div>
          <div className="creat-full mt-t-60">
            <div className="creat-box-02">
              <div className="col-md-4 img-sec">
                <div className="img-box upload-content">
                  <video src={currentVideoList[0]} className="full"></video>
                </div>
                <div className="creat-add-btn mt-t-15">
                  <form action="" method="POST" encType="multipart/form-data">
                    <ul>
                      {
                        currentVideoList.map((_video:any, index:any)=> (
                          <li key={index}>
                            <div className="inner-box">
                              <video src={_video}></video>
                            </div>
                            <span className="remove-video" {...index} onClick={() => removeImageItem(index)}><i className="fa fa-times"></i></span>
                          </li>
                        ))
                      }
                      <li className="upload-content-block">
                        <img src="/quickplay/images/creat-add.svg" />
                        <input type="file" name="" className="file-upload-btn-input dropzone" accept=".mp4,.mpeg,.avi" onChange={handleFileUploadChange} />
                      </li>
                    </ul>
                  </form>
                </div>
              </div>

              <div className="col-md-8 content-sec">
                <div className="rel-pos">
                  <div className="section-loader content-upload-loader" 
                      style={{
                        "display": `${displayLoader}`
                      }}>
                    <div className="preloader" id="loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
                </div>
                <form className="creat-box-02-form" onSubmit={handlePostSubmit}>
                  {errors.errorMessage && <div className="alert alert-danger" role="alert">{errors.errorMessage}</div>}
                  {success.sucessMessage && <div className="alert alert-success success-alert" role="alert">{success.sucessMessage}</div>}
                  <div className="input-grp first-input-box">
                    <label>Titile</label>
                    <input type="text" className="custom-input" name="title" value={formData.title} onChange={handleInputChange} />
                    {errors.title && <span className="error">{errors.title}</span>}
                  </div>
                  <div className="input-grp mt-t-30">
                    <label>Description</label>
                    <textarea  rows={4} className="custom-input" name="description" value={formData.description} onChange={handleInputChange}></textarea>
                    {errors.description && <span className="error">{errors.description}</span>}
                  </div>
                  <div className="input-grp mt-t-30">
                    <label>Choose Category</label>
                    <select className="custom-input category-dropdown" name="category" value={formData.categoryId} onChange={handleCategoryChange}>
                        <option value="">Choose Category</option>
                        { categoryOptions.map((option:any) => (
                            <option value={option.categoryId} key={option.categoryId}>
                              {option.categoryName}
                            </option>
                          ))
                        }
                    </select>
                    {errors.category && <span className="error">{errors.category}</span>}
                  </div>
                  <div className="input-grp mt-t-30 term-box">
                    <ul>
                      <li><input type="checkbox" name="term_cond" onChange={e => setIsTermsChecked(e.target.checked)}  /></li>
                      <li className="sml-text">I agree to the &nbsp; <a href="policy.html" className='terms-link'> Terms & Conditions</a></li>
                      {errors.terms && <span className="error">{errors.terms}</span>}
                    </ul>
                  </div>
                  <div className="input-grp mt-t-60 submit-box text-center">
                    <ul className="btn-ul">
                      <li>
                        <button type="submit" className="new-custom-btn-b upload-content">Upload Post</button>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}


export default CreatePostStepTwo;
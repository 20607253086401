import React, { useEffect, useState } from 'react';
import CommonModal from '../Modal';
import PropTypes from 'prop-types';
import quickplay from 'quickplay';

const FollowModal = ({ show, onHide, type, userData }:any) => {
  const [usersData, setUsersData]:any = useState([]);

  useEffect(() => {
    async function getData() {
      const { userId } = userData ?? {};
      const userModuleObj = new quickplay.QuickPlayUserModule();
      let followResponse:any = {};
      if (type === 'following') {
        followResponse = await userModuleObj.getFollowingList({
          userId: userId,
        });
      } else {
        followResponse = await userModuleObj.getFollowerList({
          userId: userId,
        });
      }
      if (followResponse.status == 200) {
        setUsersData(followResponse.data.result);
      }
    }
    getData();
  }, [type, userData]);

  return (
    <CommonModal
      show={show}
      onHide={onHide}
      title={type}
      titleProps={{
        style: {
          background: '#e6ebfb',
          padding: '10px 0',
          borderRadius: 5,
          margin: '3rem 0px 2rem',
          fontSize: '18px',
          textTransform: 'capitalize',
        },
      }}
    >
      <button className="close" onClick={onHide}>
        &times;
      </button>
      {!usersData.length ? (
        <h4>
          {' '}
          {`No ${
            type.charAt(0).toUpperCase() +
            type
              .split('')
              .splice(1, type.length - 1)
              .join('')
          }`}
        </h4>
      ) : (
        usersData.map((user:any) => {
          const { userId, userName, fullName, profileImageUrl } = user;
          // console.log("userData",user);
          return (
            <div key={userId} className="follower-list">
              <div className="left-wrapper">
                <div className="profile-img">
                  <a href={`profile/${userId}`}>
                    <img src={profileImageUrl} alt="" />
                  </a>
                </div>
                <div className="user-info">
                  <a href={`profile/${userId}`}>
                    <h5>{fullName}</h5>
                    <h6>{userName}</h6>
                  </a>
                </div>
              </div>
              {false && (
                <div className="right-wrapper">
                  <button className="follow-profile" id={userId}>
                    Follow
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
    </CommonModal>
  );
};

FollowModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.string,
  userData: PropTypes.object,
};

export default FollowModal;


import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    deviceId: "23424dsffsf",
    deviceType: "android",
  });
  const [errors, setErrors]: any = useState({});
  const [passwordType, setPasswordType] = useState("password");

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!formData.email) {
      newErrors.email = 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email id';
    }

    if (!formData.password) {
      newErrors.password = 'Please enter your password';
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  };

  const handleSubmit = async (event: any) => {
    //Prevent page reload
    event.preventDefault();
    console.log("validateForm()", validateForm())
    if (validateForm()) {
      var authLoginInput = new quickplay.QuickPlayAuthModule();
      var userSignInResponse = await authLoginInput.AuthRawData(formData);
      console.log("Success DATA", userSignInResponse);
      if (userSignInResponse.status == 200) {
        if (userSignInResponse.data.statusCode == 2001) {
          var userModuleObj = new quickplay.QuickPlayUserModule()
          var userConfigResponse = await userModuleObj.getUserMetaIds('');
          if (
            userConfigResponse.status == 200 ||
            userConfigResponse.status == 201
          ) {
            let following = userConfigResponse.data.result.following || [];
            localStorage.setItem("following", JSON.stringify(following));
          }
          navigate('/');

        } else {
          setErrors({ "loginRespError": userSignInResponse.data.statusMessage });
        }
      } else {
        setErrors({ "loginRespError": userSignInResponse.data.statusMessage });
      }

    }
  };

  return (
    <div className="sign-full">
      <div className="sign-body">
        <div className="res-logo desk-none text-center">
          <a href=""><img src="./quickplay/images/Brand.svg" style={{ width: '200px', height: '80px' }} /></a>
        </div>

        <h3 className="text-blk ft-600 res-none tab-res-none">
          Welcome to QuickPlay
        </h3>
        {errors?.loginRespError && <p className="error">{errors?.loginRespError}</p>}
        <form
          action=""
          method="post"
          id="signInForm"
          onSubmit={handleSubmit}
          className="mt-t-40"
        >
          <div className="input-grp first-input-box">
            <label>Email Address</label>
            <input type="text" name="email" id="email" placeholder="" value={formData.email} onChange={handleInputChange} />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="input-grp">
            <label>Password</label>
            <div className="over-icon">
              <input
                type={passwordType}
                id="password"
                name="password"
                placeholder=""
                value={formData.password}
                onChange={handleInputChange}
              />
              <span onClick={togglePassword}> {passwordType === "password" ? <img src="./quickplay/images/eye.svg" /> : <i className="fa fa-eye-slash"></i>}</span>
            </div>
            {errors?.password && <span className="error">{errors?.password}</span>}
          </div>
          {/* <div className="input-grp text-right">
            <a href="/forgot-password" className="link mt-t-15">
              Forgot Password?
            </a>
          </div> */}

          <div className="input-grp">
            <input
              type="submit"
              className="user-sign-in submit-button"
              value="Sign In"
              name=""
            />
          </div>
          {/* <div className="social-login">
            <p className="mt-t-15">Or sign in with social media</p>
            <div className="login-icons">
              <a href="#" className="google-login-button">
                <img src="./quickplay/images/google.svg" />
              </a>
              <a href="#" className="login-with-facebook">
                <img src="./quickplay/images/fb.svg" />
              </a>
            </div>
          </div> */}
          <div className="input-grp text-center">
            <div className="link-text" style={{ "display": `block` }}>
              <span>Do not have an account?</span>&nbsp;
              <a href="/" className="link">Guest Login?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}


export default LoginForm;
import React, { useEffect, useState } from "react";
import quickplay from "quickplay";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CommentsList from "../common/Comments/List";
import Report from "./Report";
import { useParams } from "react-router-dom";
// import LoginModal from '../PSAW-UI/LoginModal';
import FeedTopic from "./Topic";
import LoginModal from "../Login-UI/LoginModal";

function ContentDetail() {
  const [showShareIcons, setShowShareIcons] = useState(false);
  const [contentInfo, setContentInfo]: any = useState(null);
  const [data, setData]: any = useState(null);
  const [comments, setComments]: any = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [currentVideo, setCurrentVideo]: any = useState({
    url: null,
    isPlaying: false,
    muted: false,
    id: null,
  });
  const [isPlayerActive, setIsPlayerActive] = useState(false);
  const [clickedPlayerButtonId, setClickedPlayerButtonId]: any = useState(null);
  const [videoLikedByUser, setVideoLikedByUser]: any = useState([]);
  // const router = useParams();
  const { contentId }: any = useParams();
  const [setIsContentVisble, isContentVisble] = useState(false);
  const [reportPost, setReportPost] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [playerIconHtml, setPlayerIconHtml] = useState(
    '<i class="fa fa-play"></i>'
  );
  const userData = JSON.parse(localStorage.getItem("userData") || "");

  useEffect(() => {
    async function fetchData() {
      try {
        /*Initilize Feed Method*/
        var feedModuleObj = new quickplay.QuickPlayFeedModule();
        var videoModuleObj = new quickplay.QuickPlayVideosModule();
        var videoModuleResponse = await videoModuleObj.getVideoById(contentId);
        if (videoModuleResponse?.status === 200) {
          let videoData = videoModuleResponse.data.result;
          setComments(videoData?.posts);
          setContentInfo(videoData?.videoData);
          setData([videoData?.videoData]);
          let likedVideoIds = [videoData.videoData.videoId];
          if (likedVideoIds && likedVideoIds.length > 0) {
            let accessToken =
              new quickplay.QuickPlayAuthModule().getAccessToken();
            accessToken
              .then(async (data: any) => {
                // handle success case
                if (data) {
                  let likeVideosResponse = await feedModuleObj.Like(
                    likedVideoIds
                  );
                  console.log("likedVideos ==", likeVideosResponse);
                  if (
                    likeVideosResponse.status == 200 ||
                    likeVideosResponse.status == 201
                  ) {
                    let likedVideos = likeVideosResponse.data.result;
                    setVideoLikedByUser(likedVideos);
                  }
                }
              })
              .catch((error: any) => {
                // handle error case
                console.error(error);
              });
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (contentId) {
      fetchData();
    }
  }, [contentId]);

  const handleVideoAudio = () => {
    setCurrentVideo((state: any) => ({ ...state, muted: true }));
  };
  const handleViewMoreComments = () => {
    fetchComments(contentInfo.commentCount);
  };
  async function fetchComments(limit = 5) {
    try {
      const activityTimelineModuleObj =
        new quickplay.QuickPlayActivityTimeline();
      const response =
        await activityTimelineModuleObj.getActivityTimelineCommentList({
          limit: limit,
          offset: 1,
          topicId: contentInfo.videoId,
        });
      if (response?.status == 200) {
        setComments(response?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const isLoggedin = async () => {
    const accessToken =
      await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  };
  const _onPlay = (event: any) => {
    let assetId: string = event.target.id;
    assetId = assetId.replace("videoPlayer_", "");
    var activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
    activityTimelineModuleObj
      .activityTimelineView({ assetId: assetId })
      .then((response) => {
        // write your logic here
        console.log("userViewed === ", response);
      });
  };

  const handleVideoPlay = (videoId: any, videoUrl: any) => {
    const videoPlayerId = `videoPlayer_${videoId}`;
    const player: any = document.getElementById("videoPlayer_" + videoId);
    const videos: any = document.querySelectorAll(".video_00");
    videos.forEach(function (video: any) {
      if (video?.id !== videoPlayerId) {
        video.pause();
      }
    });
    if (player.paused) {
      player.play();
      setIsPlayerActive(true);
      setClickedPlayerButtonId(videoId);
      setCurrentVideo({ url: videoUrl, isPlaying: true });
    } else {
      player.pause();
      setIsPlayerActive(false);
      setClickedPlayerButtonId("");
    }
  };
  const toggleReportModal = () => {
    setShowReportModal((state) => !state);
  };

  const toggleReportPost = async () => {
    const isUserLoggedIn: any = await isLoggedin();
    if (isUserLoggedIn) {
      setReportPost((state) => !state);
    } else {
      setShowLoginModal(true);
    }
  };

  const renderFirstContainer = (contentInfo: any) => {
    // console.log(
    //   '🚀 ~ file: ContentDetail.jsx:135 ~ renderFirstContainer ~ contentInfo:',
    //   contentInfo,
    // );
    return (
      <div className="post-box-parent first_div">
        <div className="post-slider-box-parent">
          {contentInfo.contentType === "image" ? (
            <div className="post-slider-box owl-carousel owl-theme">
              {contentInfo.contentUrls.map(
                (_imageContent: any, _index: any) => (
                  <div key="{_index}" className="item">
                    <img src={_imageContent.urls} className="full" />
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="post-slider-box-02 video video-player" style={{}}>
              {renderVideoElement(contentInfo)}
            </div>
          )}
          {renderProducts(contentInfo)}
          {renderBottomPost(contentInfo)}
        </div>
      </div>
    );
  };

  const renderSecondContainer = (contentInfo: any) => {
    console.log(
      "🚀 ~ file: ContentDetail.jsx:158 ~ renderSecondContainer ~ contentInfo:",
      contentInfo
    );
    let contentDescription = contentInfo.description;
    let isFollowing = false;
    let followingList: any = localStorage.getItem("following");
    if (
      followingList !== undefined &&
      followingList !== null &&
      followingList !== "undefined"
    ) {
      followingList = JSON.parse(followingList);
      if (followingList.includes(contentInfo.user.userId)) {
        isFollowing = true;
      }
    }
    let profilePictureUrl =
      contentInfo?.user?.profileImageUrl || "./quickplay/images/mini-pro.png";
    return (
      <div className="post-detail-box-parent second_div">
        <div className="post-detail-box">
          <div className="top-details">
            <ul>
              <li className="img-li">
                <div className="img-holder">
                  <a href={"/profile/" + contentInfo.user.userId}>
                    <img src={profilePictureUrl} />
                  </a>
                </div>
              </li>
              <li>
                <h3 className="ft-500">
                  <a href={"/profile/" + contentInfo.user.userId}>
                    {contentInfo.user.fullName}
                  </a>
                </h3>
              </li>
              <li>
                {userData?.userId !== contentInfo?.user?.userId && (
                  <button
                    className={`follow-user follow-user-${contentInfo?.user?.userId}`}
                    id={`follow-${contentInfo?.user?.userId}`}
                    onClick={(e) =>
                      handleFollowEvent(e, contentInfo?.user?.userId)
                    }
                  >
                    {isFollowing ? "Unfollow" : "Follow +"}
                  </button>
                )}
              </li>
            </ul>
            <div className="sml-text">{contentInfo.title}</div>
            <div className="c-text">
              {showMore
                ? contentDescription
                : `${contentDescription.substring(0, 100)}`}{" "}
              {renderDescription(contentDescription)}
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  };
  const formatLargeNumber = (count: number) => {
    if (count < 1000) {
      return count.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return (count / 1000000).toFixed(1) + "M";
    }
  };
  const convertKToNumeric = (kValue: any) => {
    if (typeof kValue === "string" && kValue.match(/(\d+(\.\d+)?)(K|M|B)?/)) {
      let match: any = kValue.match(/(\d+(\.\d+)?)(K|M|B)?/);
      let numericValue = parseFloat(match[1]);
      let modifier = match[3];
      if (modifier) {
        if (modifier === "K") {
          numericValue *= 1000;
        } else if (modifier === "M") {
          numericValue *= 1000000;
        } else if (modifier === "B") {
          numericValue *= 1000000000;
        }
      }
      return numericValue;
    }
    return null; // Return null for invalid input
  };
  const renderProducts = (contentInfoDetails: any) => {
    if (contentInfoDetails.shoppable) {
      return (
        <div className="horizontal_scroller">
          <OwlCarousel
            dots={false}
            items={4}
            autoplay={true}
            className="list list-inline profile-box-slider owl-carousel owl-theme top-profile-list"
          >
            {contentInfoDetails.products.map((product: any) => {
              return (
                <div key={product.id} className="card-info d-flex">
                  <div className="left_img">
                    <a href={product.shoppableLink}>
                      <img src={product.imageUrl}></img>
                    </a>
                  </div>
                  <div className="right_info">
                    <h6>
                      <a href={product.shoppableLinks}>{product.productName}</a>
                    </h6>
                    <h5>${product.mrp}</h5>
                    <a className="add_cart" href={product.shoppableLink}>
                      {product.callToAction || "More Info"}
                    </a>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      );
    }
  };
  const renderSimpleVideoElement = (contentInfo: any) => {
    // let playerIconHtml = `<i className="fa fa-play" aria-hidden="true"></i>`;
    // if (currentVideo.id === contentInfo.videoId) {
    //   playerIconHtml = '';
    // }
    return (
      <>
        <video
          className="video_00"
          width="100%"
          poster={contentInfo.thumbnailUrl}
          id={"videoPlayer_" + contentInfo.videoId}
          data-url={contentInfo.downloadUrl}
          muted={currentVideo.muted}
          onPlay={(event) => _onPlay(event)}
        >
          <source src={contentInfo.downloadUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="player-controls">
          <button
            className={`play-button ${isPlayerActive ? " active" : ""}`}
            title="Play"
            onClick={(e) =>
              handleVideoPlay(contentInfo.videoId, contentInfo.downloadUrl)
            }
            dangerouslySetInnerHTML={{
              __html: isPlayerActive ? "" : playerIconHtml,
            }}
          ></button>
          {currentVideo.isPlaying && (
            <>
              <button
                className="unmute-button"
                title="Mute"
                onClick={() => handleVideoAudio()}
              >
                <i className="fa fa-volume-up" aria-hidden="true"></i>
              </button>
              <button
                className="mute-button"
                title="Unmute"
                onClick={() => handleVideoAudio()}
              >
                <i className="fa fa-volume-off" aria-hidden="true"></i>
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  const renderVideoElement = (contentInfo: any) => {
    return renderSimpleVideoElement(contentInfo);
  };

  const renderDescription = (description: any) => {
    return (
      <>
        {description.length > 100 && (
          <>
            <a
              className="text-b show-more-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </a>
          </>
        )}
      </>
    );
  };

  const renderBottomPost = (contentInfoDetails: any) => {
    let websiteUrl = encodeURI("./");
    var usedHashTagTitle = Array.isArray(contentInfoDetails.hashtagTitles)
      ? contentInfoDetails.hashtagTitles.join(",")
      : "";
    let likeByuser =
      videoLikedByUser.indexOf(contentInfoDetails.videoId) > -1
        ? "active likedByUser"
        : "";
    return (
      <>
        <div className="post-slider-box-bottom">
          <div className="strip">
            <ul className="post-slider-box-bottom-ul">
              <li>
                <a
                  href="#"
                  className={`like-video ${likeByuser} like-post-${contentInfoDetails?.videoId}`}
                  data-videoid={contentInfoDetails.videoId}
                  onClick={(e) =>
                    handleLikeEvent(e, contentInfoDetails?.videoId)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 33.899 30.435"
                  >
                    <path
                      id="Heart"
                      d="M129.908,216.734a8.443,8.443,0,0,0-6.28-2.734,7.9,7.9,0,0,0-4.934,1.7,10.08,10.08,0,0,0-1.995,2.083A10.091,10.091,0,0,0,114.7,215.7a7.9,7.9,0,0,0-4.933-1.7,8.444,8.444,0,0,0-6.281,2.734,9.814,9.814,0,0,0-2.491,6.7,11.687,11.687,0,0,0,3.114,7.65,66.4,66.4,0,0,0,7.8,7.317c1.08.92,2.3,1.964,3.576,3.076a1.843,1.843,0,0,0,2.427,0c1.271-1.111,2.5-2.156,3.577-3.077a66.366,66.366,0,0,0,7.8-7.316,11.686,11.686,0,0,0,3.114-7.65,9.814,9.814,0,0,0-2.491-6.7Zm0,0"
                      transform="translate(-99.75 -212.75)"
                      fill="none"
                      stroke="#777"
                      strokeWidth="2.5"
                    />
                  </svg>
                  <span
                    className={`like-count`}
                    id={`like-count-${contentInfoDetails?.videoId}`}
                  >
                    {" "}
                    {formatLargeNumber(contentInfoDetails.likeCount)}
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/quickplay/images/comment.svg" />
                  <span className="comment-count">
                    {contentInfoDetails.commentCount}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={"content/" + contentInfoDetails.videoId}
                  className="social-share-options"
                  onClick={() => setShowShareIcons((prevState) => !prevState)}
                >
                  <img src="/quickplay/images/share.svg" />
                  <span className="share-count">
                    {formatLargeNumber(contentInfoDetails.shareCount)}
                  </span>
                </a>
                {showShareIcons && (
                  <ul className="social-share">
                    <li>
                      <a
                        href={
                          "https://www.facebook.com/sharer.php?u=" + websiteUrl
                        }
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://twitter.com/intent/tweet?url=${websiteUrl}&text=${contentInfoDetails.description}&hashtags=${usedHashTagTitle}&`}
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${websiteUrl}`}
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className="feed-options float-r" onClick={toggleReportPost}>
                <button className="vert-dots">
                  <img
                    src="/quickplay/images/blue-dot.svg"
                    className="dot-options"
                    alt=""
                  />
                </button>
                {reportPost && (
                  <ul className="dot-suboption">
                    <li onClick={() => toggleReportModal()}>
                      <a
                        href="javascript:void(0);"
                        className="report-button"
                        data-action="video"
                      >
                        <span>
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        Report Post
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
          <div className="comment-box-parent">
            {comments.length > 5 && (
              <div
                className="text ft-500 view-previous-comment"
                onClick={() => handleViewMoreComments()}
                role="link"
              >
                <span className="comment-count ">
                  {contentInfo.commentCount - 5}
                </span>
                view previous comments
              </div>
            )}
            <CommentsList
              videoId={contentInfoDetails.videoId}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </div>
      </>
    );
  };

  const handleLikeEvent = async (e: any, videoId: string) => {
    e.preventDefault();
    const assetId = e.currentTarget.getAttribute("data-videoid");
    let selfTarget = e.currentTarget;
    let likeElement: any = document.getElementById("like-count-" + assetId);
    let videoLikedByMe = selfTarget.classList.contains("active");
    let accessToken =
      await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (
      accessToken === "" ||
      accessToken === undefined ||
      accessToken === null
    ) {
      // open Login Modal
      setShowLoginModal(true);
      return;
    }
    const activityTimelineModuleObj =
      await new quickplay.QuickPlayActivityTimeline();
    if (videoLikedByMe) {
      let userPostedComment =
        await activityTimelineModuleObj.activityTimelineUnLike({
          assetId: assetId,
        });
      if (userPostedComment && userPostedComment.status == 200) {
        selfTarget.classList.remove("active");
        let likeCount = parseInt(likeElement.innerHTML) - 1;
        likeCount = likeCount >= 0 ? likeCount : 0;
        likeElement.innerHTML = likeCount;
      }
    } else {
      activityTimelineModuleObj
        .activityTimelineLike({ assetId: assetId })
        .then((response) => {
          // write your logic here
          console.log("userPostedComment === ", response);
          if (response && response.status == 200) {
            selfTarget.classList.add("active");
            let likeCount = parseInt(likeElement.innerHTML) + 1;
            likeElement.innerHTML = likeCount;
            return;
          }
        });
    }
  };

  const handleShareEvent = async (e: any) => {
    e.preventDefault();
    const assetId = e.currentTarget.getAttribute("data-videoid");
    var activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
    await activityTimelineModuleObj.activityTimelineLike({
      assetId: assetId,
    });
  };

  const handleFollowEvent = async (event: any, userId: string) => {
    let accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    if (
      accessToken === "" ||
      accessToken === undefined ||
      accessToken === null
    ) {
      // open Login Modal
      // setShowLoginModal(true);
      return;
    }
    const userModuleObj = new quickplay.QuickPlayUserModule();
    let followBtnText = event.target.innerText;
    var formData = {
      followingId: userId,
      isFollow: followBtnText == "Follow +" ? true : false,
    };
    const userModuleResponse = await userModuleObj.followUser(formData);
    if (
      userModuleResponse.status === 201 ||
      userModuleResponse.status === 200
    ) {
      let followingList: any = localStorage.getItem("following");
      if (
        followingList != undefined &&
        followingList != null &&
        followingList != "undefined"
      ) {
        followingList = JSON.parse(followingList);
        let target: any = document.getElementById("follow-" + userId);
        if (target.innerHTML === "Follow +") {
          followingList.push(userId);
          localStorage.setItem("following", JSON.stringify(followingList));
          target.innerHTML = "Unfollow";
          const followButtons = document.querySelectorAll(
            ".follow-user-" + userId
          );
          followButtons.forEach(function (fb) {
            fb.innerHTML = "Unfollow";
          });
        } else {
          const newFollowing = followingList.filter(
            (value: any) => value !== userId
          );
          localStorage.setItem("following", JSON.stringify(newFollowing));
          target.innerHTML = "Follow +";
          const followButtons = document.querySelectorAll(
            ".follow-user-" + userId
          );
          followButtons.forEach(function (fb) {
            fb.innerHTML = "Follow +";
          });
        }
      }
    }
  };

  if (!contentInfo) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <div className="full-post-box ordering col-md-8" key={contentInfo._id}>
        {/* {data.map((contentInfo: any) => (
        <FeedTopic
          key={contentInfo._id}
          contentInfo={contentInfo}
          videoLikedByUser={videoLikedByUser}
          setShowLoginModal={setShowLoginModal}
        />
      ))} */}
        {/* </div> */}
        {contentInfo && (
          <>
            {renderFirstContainer(contentInfo)}
            {renderSecondContainer(contentInfo)}
            {showReportModal && (
              <Report
                show={showReportModal}
                onHide={() => toggleReportModal()}
                type="video"
                payloadData={{
                  assetId: contentInfo.videoId,
                  userId: contentInfo.userId,
                }}
              />
            )}
            {showLoginModal && (
              <LoginModal
                show={showLoginModal}
                handleClose={() => setShowLoginModal(false)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ContentDetail;

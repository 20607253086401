import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import quickplay from 'quickplay';

import CommonModal from '../common/Modal';

const feedModuleObj = new quickplay.QuickPlayFeedModule();

function Report({ show, onHide, type, payloadData }:any) {
  const [selectedReason, setselectedReason] = useState(null);
  const [reportReasons, setReportReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleReasonChange = (e:any) => {
    setselectedReason(e.target.value);
  };

  const handleReport = async () => {
    const userData:any = JSON.parse(localStorage.getItem('userData') ||"{}");
    const { userId = '', assetId = '', postId = '' } = payloadData || {};
    const payload = {
      userId: userId,
      actingUserId: userData.userId,
      assetId: assetId,
      reason: selectedReason,
      event: 'report',
      type: type,
      postId: postId, // postId will only go when we are reporting a comment
    };
    const response = await feedModuleObj.Report(payload);
    if (response.status === 201) {
      onHide();
    }
  };

  async function getReportReasons({ type }:any) {
    try {
      const response = await feedModuleObj.ReportReason();
      setReportReasons(response.data.result[type]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReportReasons({ type });
  }, [type]);

  return (
    <CommonModal
      show={show}
      title="Report Reason"
      subTitle="Let us know the reason to report this comment"
      onHide={onHide}
      id={type === 'user' ? 'reportUserModal' : 'report-modal'}
    >
      {isLoading && <p>Loading....</p>}
      {!isLoading &&
        reportReasons.length &&
        reportReasons.map((reason:any) => {
          return (
            <div key={reason} className="radio-inputfield">
              <label htmlFor={reason.replace(' ', '_')} >{reason}</label>
              <input
                type="radio"
                id={reason.replace(' ', '_')}
                name="report_option"
                value={reason}
                onChange={handleReasonChange}
                style={{ backgroundColor: 'red', color: 'white' }}
              />
              <br />
            </div>
          );
        })}
      <div className="bottom-button-group">
        <button className="close" onClick={onHide}>
          Cancel
        </button>
        <button onClick={handleReport}>Report</button>
      </div>
    </CommonModal>
  );
}

Report.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.string,
  payloadData: PropTypes.object,
};

export default Report;

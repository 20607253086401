import React from 'react';
import Sidebar from '../components/Layouts/Sidebar';
import ActivityTimeline from '../components/ActivityTimeline/ActivityTimeline';

const ActivityTimelinePage = () => {
  return (
    <div className="full-sec">
      <div className="left-sec col-md-3">
        <Sidebar />
      </div>
      <div className="activity-timeline right-sec col-md-9">
        <ActivityTimeline />
      </div>
    </div>
  );
};

export default ActivityTimelinePage;

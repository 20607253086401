import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import quickplay from 'quickplay';
import queryString from 'querystring';

function ActivityTimeline() {
  const [timelineDetails, setTimelineDetails]:any = useState(null);
  const [currentTabData, setCurrentTabData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab  = searchParams.get("tab") || 'like';

  useEffect(() => {
    fetchTabData(tab);
  }, [tab]);

  useEffect(() => {
    fetchVideos();
  }, []);

  if (!timelineDetails && !currentTabData) {
    return <p>Loading...</p>;
  }

  const handleContentDetail = (contentInfo:any) => {
    navigate(`/content/${contentInfo?.metakeys?.videoId}`);
  };

  const handleClickOnTab = (tab:any) => {
    navigate(location.pathname+"?"+queryString.stringify({tab}));
    // router.replace({
    //   pathname: router.pathname,
    //   query: queryString.stringify({ tab }),
    // });
  };

  async function fetchVideos() {
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline()
      const activityTimelineList =
        await activityTimelineModuleObj.getActivityTimelineUserDetails({
          event: 'post',
          limit: 10,
          offset: 1,
        });
      if (activityTimelineList.status === 200) {
        const activityTimelineInfo = activityTimelineList.data.result;
        setTimelineDetails(activityTimelineInfo);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTabData(tab:any) {
    try {
      var limit = 10;
      var offset = 1;
      var activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline()
      var activityTimelineVideoList =
        await activityTimelineModuleObj.getActivityTimelineList({
          event: tab,
          limit: limit,
          offset: offset,
        });
      if (activityTimelineVideoList.data.statusCode == 2001) {
        let videos = activityTimelineVideoList.data.result;
        console.log('videos', videos);
        setCurrentTabData(videos);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="full-box profile-full-box">
      <div className="profile-page-header">
        <a href="./my-profile">
          <i className="fa fa-long-arrow-left"></i>
          Back
        </a>
        <h5>Activity Timeline</h5>
      </div>

      <div className="profile-page-head">
        <ul className="profile-page-head-ul list-none">
          <li className="profile-page-head-avatar">
            <div className="img-sec">
              <img
                src={timelineDetails?.profileImageUrl || '/quickplay/images/Profile.png'}
                id="profileImage"
                className="bg-img-02 profileImage"
                alt="ProfileImage"
              />
            </div>
          </li>

          <li className="profile-page-head-content">
            <ul className="profile-page-head-content-inner">
              <li>
                <h4 id="displayName">{timelineDetails?.fullName}</h4>
                <h5 id="userId">{timelineDetails?.userName}</h5>
                <div className="desk-none">
                  <a href="#" className="res-edit">
                    Edit
                  </a>
                </div>
              </li>

              <li
                className={tab === 'like' ? 'active' : ''}
                onClick={() => handleClickOnTab('like')}
              >
                <h5>Liked</h5>
                <span id="likedCount">{timelineDetails?.likeCount}</span>
              </li>

              <li
                className={tab === 'comment' ? 'active' : ''}
                onClick={() => handleClickOnTab('comment')}
              >
                <h5>Comments</h5>
                <span id="commentCount">{timelineDetails?.commentCount}</span>
              </li>

              <li
                className={tab === 'share' ? 'active' : ''}
                onClick={() => handleClickOnTab('share')}
              >
                <h5>Shared</h5>
                <span id="sharedCount">{timelineDetails?.shareCount}</span>
              </li>
              <li className="report-user-button" style={{ display: 'none' }}>
                <button className="report" id="report-user" data-action="user">
                  Report
                </button>
              </li>
            </ul>
            <div className="modal fade" id="reportUserModel" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content text-center">
                  <div className="modal-body">
                    <h5>
                      <strong>Report Reasons</strong>
                    </h5>
                    <p>Let me know the reason to report this comment.</p>
                    <div className="reason-wrpr">
                      <form action="" method="post" id="report-user-form">
                        <div id="report-user-content"></div>
                        <button className="submit-btn btn">Submit</button>
                        <button className="cancel-btn btn">Cancel</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="inner-box arrival-box">
        <div className="row tab-content userVideoData" id="tab1">
          {currentTabData.length ? (
            currentTabData.map((video:any, index) => {
              let thumbnailUrls = video.metakeys.thumbnailUrls
                ? video.metakeys.thumbnailUrls[0]
                : video.metakeys.thumbnailUrl;
              return (
                <div
                  className="col-md-3 col-sm-3 col-xs-6 box text-center"
                  key={index}
                >
                  <div
                    className="img-box open-video-detail"
                    id={video.metakeys.videoId}
                    style={{
                      background: `url(${thumbnailUrls}) center`,
                      height: '227px',
                      maxHeight: '227px',
                      borderRadius: '9px',
                      backgroundSize: 'cover',
                    }}
                    onClick={() => handleContentDetail(video)}
                  >
                    <span className="av-icon">
                      <img src="/quickplay/images/folder-icon.svg" />
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <h3>No Data</h3>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivityTimeline;

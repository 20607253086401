import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from '../common/Modal';

const LoginModal = ({ show, handleClose }:any) => {
  return (
    <CommonModal show={show} onHide={handleClose} id="signupmodel">
      <p>Sign In</p>

      <div className="text">Please login to continue</div>

      <a href="/sign-in" className="custom-btn-b">
        Sign In
      </a>
    </CommonModal>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default LoginModal;

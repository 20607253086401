import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModal from '../../Login-UI/LoginModal';

function Sidebar(props:any) {
  const location = useLocation();
  const {showProfile=true} = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();
  const [userDetail, setUserDetail]: any = useState(null);
  const [userInfo, setUserInfo]: any = useState(false);
  const [editProfile, setEditProfile]: any = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (data) {
          setIsLoggedin(true);
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  }, []);
  useEffect(() => {
    async function userDetails() {
      var userModuleObj = new quickplay.QuickPlayUserModule();
      let userBasicInfo: any = localStorage.getItem("userData");
      let userId = "";
      if (userBasicInfo && userBasicInfo != undefined) {
        userBasicInfo = JSON.parse(userBasicInfo);
        userId = userBasicInfo.userId;
      };
      var userModuleResponse = await userModuleObj.getUserDetails(userId);
      if (userModuleResponse.status == 200) {
        let userInfo = userModuleResponse.data.result;
        if (userInfo) {
          setUserInfo(true);
          setUserDetail(userInfo);
        }
      }
    }
    userDetails();
  }, []);
  const _navigateToProfile = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        console.log('access Token', data);
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate('/my-profile');
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };

  const _navigateToNotification = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate('/notification');
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };

  const toggleEditProfilePanel = () => {
    setEditProfile(true);
    setShowOverlay(true);
  };
  const _logoutUser = () => {
    localStorage.removeItem('following');
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    navigate('/sign-in');
  };

  return (
    <>
      <div className="sidebar">
        {/* <a href="./" className="logo">
          <img src="/quickplay/images/logo.svg" />
        </a> */}
        {userInfo && showProfile && <ul
          className="home-profile-info profile-page-head-ul list-none">
          <li className="profile-page-head-avatar">
            <div className="img-sec">
              <img
                src={userDetail?.profileImageUrl}
                id="profileImage"
                className="bg-img-02 profileImage"
              />
              {/* <span>
                <img
                  src="../quickplay/images/pro-edit.svg"
                  id="editIcon"
                  className="edit-profile"
                  onClick={toggleEditProfilePanel}
                />
              </span> */}
            </div>
          </li>
          <li className="home-head profile-page-head-content">
            <ul className="user-info profile-page-head-content-inner">
              <li>
                <h4 id="displayName">{userDetail?.fullName}</h4>
                <h5 id="userId">{userDetail?.userName}</h5>
                <div className="desk-none">
                  <a href="javascript:void(0)" className="res-edit">Edit</a>
                </div>
              </li>
            </ul>
            <ul className="followers-info profile-page-head-content-inner">
              <li>
                <h5>Followers</h5>
                <span id="followerCount">{userDetail?.followersCount}</span>
              </li>
              <li>
                <h5>Following</h5>
                <span id="followingCount">{userDetail?.followingCount}</span>
              </li>
              <li>
                <h5>Posts</h5>
                <span id="videoCount">{userDetail?.videoCount}</span>
              </li>
            </ul>
          </li>
        </ul>}
        <ul className="sidebar-ul">
          <li className={location.pathname === '/' ? 'active' : ''}>
            <a href="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="home_1_" data-name="home (1)" d="M23.121,9.074,15.536,1.484a5.006,5.006,0,0,0-7.072,0L.879,9.074A2.981,2.981,0,0,0,0,11.2V21.02a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.2A2.981,2.981,0,0,0,23.121,9.074ZM15,22.02H9V18.084a3,3,0,1,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.084a5,5,0,1,0-10,0V22.02H3a1,1,0,0,1-1-1V11.2a1.009,1.009,0,0,1,.293-.707L9.878,2.9a3.007,3.007,0,0,1,4.244,0l7.585,7.591a1.009,1.009,0,0,1,.293.7Z" transform="translate(0 -0.021)" fill="#596970"></path>
                </svg>
              Home Feed
            </a>
          </li>

          <li className={location.pathname === '/discover' ? 'active' : ''}>
            <a href="/discover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="search"
                  d="M23.675,22.263,17.714,16.3A10,10,0,1,0,16.3,17.714l5.961,5.961a1,1,0,1,0,1.412-1.412ZM9.987,17.976a7.989,7.989,0,1,1,7.989-7.989,7.989,7.989,0,0,1-7.989,7.989Z"
                  transform="translate(0.032 0.032)"
                />
              </svg>
              Discover
            </a>
          </li>

          <li className={location.pathname === '/notification' ? 'active' : ''}>
            <a
              href="#"
              className="go-to-notification"
              onClick={_navigateToNotification}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.753"
                height="24.003"
                viewBox="0 0 21.753 24.003"
              >
                <path
                  id="bell_1_"
                  data-name="bell (1)"
                  d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836a2.977,2.977,0,0,1-.5,2.618Z"
                  transform="translate(-0.986 -0.002)"
                />
              </svg>
              Notifications
            </a>
          </li>

          <li className={location.pathname === '/my-profile' ? 'active' : ''}>
            <a
              href="javascript:void(0)"
              className="go-to-user-profile"
              onClick={_navigateToProfile}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="24"
                viewBox="0 0 18 24"
              >
                <g id="user" transform="translate(-3)">
                  <path
                    id="Path_4189"
                    data-name="Path 4189"
                    d="M12,12A6,6,0,1,0,6,6a6,6,0,0,0,6,6ZM12,2A4,4,0,1,1,8,6a4,4,0,0,1,4-4Z"
                  />
                  <path
                    id="Path_4190"
                    data-name="Path 4190"
                    d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,1,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
                  />
                </g>
              </svg>
              Profile
            </a>
          </li>
          {isLoggedin && (
            <li onClick={_logoutUser}>
              <a href="javascript:void(0)" className="user-sign-out">
                <svg
                  className="svg-icon"
                  style={{
                    height: '1em',
                    verticalAlign: 'middle',
                    fill: 'currentColor',
                    overflow: 'hidden',
                  }}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M856.8 389.8c-18.9-44.7-45.9-84.8-80.4-119.2-18.9-18.9-39.5-35.6-61.7-49.9-10-6.5-23.3 0.6-23.3 12.6 0 5.1 2.6 9.9 6.9 12.6 95 61.5 158 168.5 158 289.8 0 190.3-154.8 345-345 345s-345-154.8-345-345c0-122.4 64.1-230.2 160.5-291.4 4.4-2.8 7-7.6 7-12.7 0-11.8-13.1-19.1-23.1-12.8-23.2 14.7-44.8 32-64.6 51.8-34.4 34.4-61.5 74.5-80.4 119.2-19.6 46.2-29.5 95.3-29.5 146s9.9 99.7 29.5 146c18.9 44.7 45.9 84.8 80.4 119.2 34.4 34.4 74.5 61.5 119.2 80.4 46.2 19.6 95.3 29.5 146 29.5 50.6 0 99.7-9.9 146-29.5 44.7-18.9 84.8-45.9 119.2-80.4s61.5-74.5 80.4-119.2c19.6-46.2 29.5-95.3 29.5-146s-10-99.8-29.6-146z"
                    fill=""
                  />
                  <path
                    d="M512 431.1c-8.8 0-16-7.2-16-16V98.2c0-8.8 7.2-16 16-16s16 7.2 16 16V415c0 8.9-7.2 16.1-16 16.1z"
                    fill=""
                  />
                </svg>
                Logout
              </a>
            </li>
          )}
        </ul>
        {isLoggedin && (
          <a
            href="/create-post"
            className="go-to-page-create-content content-btn"
          >
            Create New Content
            <img src="/quickplay/images/circle+.svg" />
          </a>
        )}
      </div>
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
      )}
    </>
  );
}

export default Sidebar;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Report from '../../Feed/Report';
// import LoginModal from '../../PSAW-UI/LoginModal';
import quickplay from 'quickplay';
import LoginModal from '../../Login-UI/LoginModal';

function Comment({ videoId, comment, handleDeleteComment, handleEditComment }: any) {
  const [isReportCommentVisible, setIsReportCommentVisible] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const {
    postId,
    commentRefId,
    userId,
    profileImageUrl,
    fullName,
    description,
  } = comment ?? {};

  const isUserIdSame =
    JSON.parse(localStorage.getItem('userData') || "{}")?.userId === userId;
  const toggleReportComment = async () => {
    const isUserLoggedIn: any = await isLoggedin();
    if (isUserLoggedIn) {
      setIsReportCommentVisible(state => !state);
    }
    else {
      setShowLoginModal(true);
    }
  };

  const toggleReportModal = () => {
    setShowReportModal(state => !state);
  };
  const isLoggedin = async () => {
    const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (accessToken) {
      return true;
    }
    else {
      return false;
    }
  };
  return (
    <div className="comment-box mt-t-15" key={postId}>
      <ul>
        <li className="img-li">
          <div className="img-holder">
            <a href={`profile/${userId}`}>
              <img src={profileImageUrl} />
            </a>
          </div>
        </li>
        <li className="content-li">
          <a href={`profile/${userId}`}>
            <h6 className="text">{fullName}</h6>
          </a>
          <span className="text-blk">{description}</span>
          {isUserIdSame && (
            <div className="edit-delete-option">
              <button
                className="edit-option"
                onClick={() =>
                  handleEditComment({ id: postId, refId: commentRefId })
                }
              >
                <img src="/quickplay/images/edit.svg" alt="" />
              </button>
              <button
                className="delete-option"
                onClick={() =>
                  handleDeleteComment({ id: postId, refId: commentRefId })
                }
              >
                <img src="/quickplay/images/delete.svg" alt="" />
              </button>
            </div>
          )}
          <a href="#" className="text-g rply-more">
            View {comment.replyCount} replies
          </a>
        </li>
      </ul>
      <div className="report-wrapper">
        <button className="comment-dots" onClick={toggleReportComment}>
          <img
            src="/quickplay/images/hor-dot.svg"
            className="dot-options"
            data-post-id={comment.postId}
            data-userid={comment.userId}
            data-action="comment"
            data-assetid={videoId}
            alt=""
          />
        </button>
        {isReportCommentVisible && (
          <ul className="dot-suboption">
            <li onClick={() => toggleReportModal()}>
              <a href="#" className="report-button" data-action="comment">
                <span>
                  <i
                    className="fa fa-exclamation-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <span className="text">Report Comment</span>
              </a>
            </li>
          </ul>
        )}
      </div>
      {showReportModal && (
        <Report
          show={showReportModal}
          onHide={() => toggleReportModal()}
          type="comment"
          payloadData={{ userId, assetId: videoId, postId }}
        />
      )}
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
      )}
    </div>
  );
}

Comment.propTypes = {
  videoId: PropTypes.string,
  comment: PropTypes.object,
  handleDeleteComment: PropTypes.func,
  handleEditComment: PropTypes.func,
};

export default Comment;

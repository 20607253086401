
import React, { useEffect,useState } from 'react';
import quickplay from 'quickplay';

function RegistrationForm() {

  const [formData, setFormData]:any = useState({
    fullName: '',
    email: '',
    password: ''
  });
  const [errors, setErrors]:any = useState({});
  const [success, setSuccess]:any = useState({});
  const [passwordType, setPasswordType] = useState("password");

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevFormData:any) => ({ ...prevFormData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors:any = {};

    if (!formData.fullName) {
      newErrors.fullName = 'Please enter your full name';
    }

    if (!formData.email) {
      newErrors.email = 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email id';
    }

    if (!formData.password) {
      newErrors.password = 'Please enter your password';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  };

  const handleSubmit = async (event:any) => {
    //Prevent page reload
    event.preventDefault();

    if (validateForm()) {
      var authLoginInput = new quickplay.QuickPlayAuthModule();
      formData.termConditionAccepted= true;
      var userSignUpResponse = await authLoginInput.SignupRawData( formData );
      const responseError:any = {};
      if (userSignUpResponse.status == 200) {
        if (userSignUpResponse.data.statusCode == 2003) {
          formData.fullName="";
          formData.email="";
          formData.password="";
          setSuccess({"registerSuccess": userSignUpResponse?.data?.statusMessage});
        }else{
          responseError.registerResponseError = userSignUpResponse?.data?.statusMessage;
          setErrors(responseError);
        }
      }else{
        responseError.registerResponseError = userSignUpResponse?.data?.statusMessage;
        setErrors(responseError);
      }
    }
  };

  return (
    <div className="sign-full">
      <div className="sign-body">
        <div className="res-logo desk-none text-center">
          <a href=""><img src="assets/images/logo.svg" /></a>
        </div>

        <h3 className="text-blk ft-600 res-none tab-res-none">
          Welcome to QuickPlay
        </h3>

        <form
          action=""
          method="post"
          id="signupForm"
          className="mt-t-40"
          onSubmit={handleSubmit}
        >
          {errors.registerResponseError && <div className="alert alert-danger" role="alert">{errors.registerResponseError}</div>}
          {success.registerSuccess && <div className="alert alert-success success-alert" role="alert">{success.registerSuccess}</div>}
          <div className="input-grp first-input-box">
            <label>Full Name</label>
            <input type="text" name="fullName" id="fullName" placeholder="" value={formData.fullName} onChange={handleInputChange} />
            {errors.fullName && <span className="error">{errors.fullName}</span>}
          </div>

          <div className="input-grp">
            <label>Email Address</label>
            <input type="text" name="email" id="email" placeholder="" value={formData.email} onChange={handleInputChange} />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="input-grp">
            <label>Password</label>
            <div className="over-icon">
              <input
                type={passwordType}
                id="password"
                name="password"
                placeholder=""
                value={formData.password}
                onChange={handleInputChange}
              />
              <span onClick={togglePassword}> { passwordType==="password"? <img src="./quickplay/images/eye.svg" /> :<i className="fa fa-eye-slash"></i> }</span>
            </div>
            {errors?.password && <span className="error">{errors?.password}</span>}
          </div>

          <div className="input-grp">
            <input
              type="submit"
              className="submit-button"
              value="Sign Up"
              name=""
            />
          </div>
          <div className="input-grp text-center">
            <div className="link-text" style={{ "display": `block` }}>
              <span>Do not have an account?</span>&nbsp;
              <a href="/" className="link">Guest Login?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}


export default RegistrationForm;
import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TopProfile() {
  const [data, setData]:any = useState(null);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const userModuleObj = new quickplay.QuickPlayUserModule();
        const userModuleResponse = await userModuleObj.getTopProfiles({});
        setData(userModuleResponse.data.result);
        setIsClient(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <div className="full-box home-feed-full-box">
      <div className="feed-box">
        <div className="box top-profile-box">
          <div className="top-profile-box-left-sec">
            <div className="rail-header">
              <h4 className="ft-500">Top Profiles</h4>
            </div>
            {isClient && (
              <OwlCarousel
                loop
                dots={false}
                items={5}
                autoplay={true}
                responsive= {{
                  0: {
                      items: 3,
                      nav: false,
                  },
                  600: {
                      items: 4,
                      nav: false,
                  },
                  1000: {
                      items: 6,
                      loop: false,
                  },
              }}
                className="list list-inline profile-box-slider owl-carousel owl-theme top-profile-list"
              >
                {data.map((item:any) => (
                  <li key={item.userId} className="item">
                    <a href={'profile/' + item.userId}>
                      <img src={item.profileImageUrl || "https://d3ibngdlgwl8mp.cloudfront.net/Profile.png"} />
                      <span className="sml-text name">
                        {item.fullName.length > 10
                          ? item.fullName.substr(0, 10) + '..'
                          : item.fullName}
                      </span>
                      <span className="followers">
                        {item.followersCount} Followers
                      </span>
                    </a>
                  </li>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopProfile;

import React, { useState } from 'react';
import quickplay from 'quickplay';

import CreateContentSidebar from '../components/Layouts/Sidebar/Content';
import Bottom from '../components/Layouts/Bottom';
import { useLocation, useNavigate } from 'react-router-dom';
const CreateTextContent = () => {
  const navigate=useNavigate();
  const [value, setValue] = useState('');
  const [termsChecked, setTermsChecked]:any = useState(false);
  const [errors, setErrors] = useState({
    description: false,
    terms: false,
  });

  const handleOnChange = (event:any) => {
    setValue(event.target.value);
  };

  const handleTermsChange = (event:any) => {
    setTermsChecked(event.target.checked);
    setErrors(state => ({ ...state, terms: false }));
  };

  const handleCreateTextContent = async (event:any) => {
    event.preventDefault();
    if (!value) {
      setErrors(state => ({ ...state, description: true }));
      return;
    }

    if (!termsChecked) {
      setErrors(state => ({ ...state, terms: true }));
      return;
    }

    let formData = {
      description: value,
      contentType: 'text',
    };
    const feedModuleObj = new quickplay.QuickPlayFeedModule();
    const feedModuleResponse = await feedModuleObj.uploadContent(formData);
    if (feedModuleResponse.status == 200) {
      console.log('text content created');
      navigate('/');
      setValue('');
    }
  };

  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-2">
          <CreateContentSidebar />
        </div>
        <div className="right-sec col-md-9 text-content-wrapper">
          <div className="full-box">
            <div className="back-header">
              <ul className="list list-inline">
                <li className="back-btn">
                  <a href="/" className="back-btn-a">
                    <img
                      src="/quickplay/images/arrow-small-right.svg"
                      alt="back-arrow"
                      width={20}
                      height={20}
                    />
                    <span>Back</span>
                  </a>
                </li>
                <li className="common-heading">Create Text</li>
              </ul>
            </div>
            <div className="main_wrapper">
              <div className="input-grp mt-t-30 term-box">
                <form
                  action="submit"
                  method="POST"
                  id="curation-form"
                  onSubmit={handleCreateTextContent}
                >
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    cols={50}
                    maxLength={500}
                    placeholder="Write something....."
                    value={value}
                    onChange={handleOnChange}
                  ></textarea>
                  {errors.description && (
                    <label
                      className="error_message text-content-error"
                      id="description_label"
                    >
                      Description is required
                    </label>
                  )}

                  <div className="word-count">
                    <span id="write-count">{value.length}</span>/
                    <span id="total-count">500</span>
                  </div>

                  <ul className="checkbox_wrapper">
                    <li>
                      <input
                        id="term_cond"
                        type="checkbox"
                        name="term_cond"
                        value={termsChecked}
                        onChange={handleTermsChange}
                      />
                    </li>
                    <li className="sml-text">
                      I agree to the &nbsp;
                      <a
                        href="policy.html"
                        className='terms-link'
                        target="_blank"
                        >
                        Terms &amp; Conditions
                      </a>
                    </li>
                  </ul>
                  {errors.terms && (
                    <label
                      className="error_message text-content-error"
                      id="checkbox_label"
                    >
                      Please select term and conditions
                    </label>
                  )}
                  <div className="bottom_btn">
                    <button className="save-draft">Save as Draft</button>
                    <button
                      className="post-now"
                      onClick={handleCreateTextContent}
                    >
                      Post Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default CreateTextContent;

import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../Login-UI/LoginModal';

function Bottom() {
  const navigate = useNavigate();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (data) {
          setIsLoggedin(true);
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  }, []);

  const _navigateToProfile = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        // console.log('access Token', data);
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate('/my-profile');
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };
  const _navigateToNotification = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate('/notification');
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };

  return (<>
    <div className="res-bootom-bar">
      <ul className="res-bootom-bar-ul">
        <li>
          <a href="/">
            <svg
              id="apps"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_4183"
                data-name="Path 4183"
                d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z"
              />
              <path
                id="Path_4184"
                data-name="Path 4184"
                d="M20,0H17a4,4,0,0,0-4,4V7a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z"
              />
              <path
                id="Path_4185"
                data-name="Path 4185"
                d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z"
              />
              <path
                id="Path_4186"
                data-name="Path 4186"
                d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="/discover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="search"
                d="M23.675,22.263,17.714,16.3A10,10,0,1,0,16.3,17.714l5.961,5.961a1,1,0,1,0,1.412-1.412ZM9.987,17.976a7.989,7.989,0,1,1,7.989-7.989,7.989,7.989,0,0,1-7.989,7.989Z"
                transform="translate(0.032 0.032)"
              />
            </svg>
          </a>
        </li>
        {isLoggedin && (
          <li className="creator-button">
            <a href="/create-post" className="go-to-page-create-content">
              <img src="/quickplay/images/creator-button.svg" />
            </a>
          </li>
        )}
        <li>
          <a  href="javascript:void(0)" className="go-to-notification" onClick={_navigateToNotification}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.753"
              height="24.003"
              viewBox="0 0 21.753 24.003"
            >
              <path
                id="bell_1_"
                data-name="bell (1)"
                d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836a2.977,2.977,0,0,1-.5,2.618Z"
                transform="translate(-0.986 -0.002)"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            className="go-to-user-profile"
            onClick={_navigateToProfile}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="24"
              viewBox="0 0 18 24"
            >
              <g id="user" transform="translate(-3)">
                <path
                  id="Path_4189"
                  data-name="Path 4189"
                  d="M12,12A6,6,0,1,0,6,6a6,6,0,0,0,6,6ZM12,2A4,4,0,1,1,8,6a4,4,0,0,1,4-4Z"
                />
                <path
                  id="Path_4190"
                  data-name="Path 4190"
                  d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,1,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
                />
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
    {showLoginModal && (
      <LoginModal
        show={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      />
    )}
  </>
  );
}

export default Bottom;

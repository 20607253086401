import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Layouts/Sidebar';
import Bottom from '../components/Layouts/Bottom';
import NotificationList from '../components/Notification/NotificationList';

const Notification = () => {
  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar />
        </div>
        <div className="right-sec col-md-9">
          <div className="full-box notification-full-box">
            <div className="heading-header">
              <div className="sub-heading">
                Notifications
                <span id="notification-count"></span>
              </div>
            </div>
            <div className="notification-box-parent mt-t-60">
              <NotificationList />
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Notification;


import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';

function ForgotPasswordForm() {
  // const router = useRouter();
  const [formData, setFormData] = useState({
    email: ''
  });
  const [success, setSuccess]:any = useState({});
  const [errors, setErrors]:any = useState({});

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors:any = {};

    if (!formData.email) {
      newErrors.email = 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email id';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (event:any) => {
    //Prevent page reload
    event.preventDefault();
    console.log("validateForm()", validateForm())
    if (validateForm()) {
      
      const authLoginInput = new quickplay.QuickPlayUserModule()
      var userSignInResponse = await authLoginInput.userForgotpassword(formData.email);
      console.log("API Response DATA", userSignInResponse );
      if (userSignInResponse.status == 200) {
        if (userSignInResponse.data.statusCode == 2001) {
          setSuccess({"forgotPasswordSuccess": 'A verification link has been sent to your email id.'});
        } else {
          setErrors({ "forgotPasswordError": userSignInResponse.data.statusMessage} );
        }
      }else{
        setErrors({ "forgotPasswordError": userSignInResponse.data.statusMessage} );
      }

    }
  };

  return (
    <div className="sign-full">
      <div className="sign-body forgot-password-body">
        <div className="back-arrow-btn-parent mt-b-20">
          <a
            href="/sign-in"
            className="back-arrow-btn"
          >
            <img src="/quickplay/images/back-arrow.svg" />
            Back
          </a>
        </div>
        <div className="clearfix"></div>

        <h3 className="text-blk ft-600">Forgot Password?</h3>
        <div className="text-blk mt-t-20 mt-b-20 text-gry res-show-text">
          Enter the email address associated with your account. We will email
          you a link to reset your password.
        </div>

        <form
          action=""
          method="post"
          id="forgotPasswordForm"
          onSubmit={handleSubmit}
        >
          {errors.forgotPasswordError && <div className="alert alert-danger" role="alert">{errors.forgotPasswordError}</div>}
          {success.forgotPasswordSuccess && <div className="alert alert-success success-alert" role="alert">{success.forgotPasswordSuccess}</div>}
          <div className="input-grp first-input-box">
            <label>Email Address</label>
            <input type="text" name="email" id="email" placeholder="" value={formData.email} onChange={handleInputChange} />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="input-grp">
            <input
              type="submit"
              className="user-sign-in submit-button"
              value="Submit"
              name=""
            />
          </div>
        </form>
      </div>
    </div>
  );

}


export default ForgotPasswordForm;
import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay';
// import DeleteAccountModal from '../PSAW-UI/DeleteAccountModal';
import FollowModal from '../common/FollowModal';
import { useNavigate } from 'react-router-dom';
import DeleteAccountModal from '../Login-UI/DeleteAccountModal';

function MyProfile() {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const navigate = useNavigate();
  const [userDetail, setUserDetail]: any = useState(null);
  const [userVideos, setUserVideos] = useState([]);
  const [userId, setUserId]: any = useState([]);
  const [editProfile, setEditProfile]: any = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const [editMobileSetting, setEditMobileSetting] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [followModalType, setFollowModalType] = useState('followers');
  const [formData, setFormData] = useState({
    fullName: '',
    userName: '',
    description: '',
    profileImageUrl: '',
  });
  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: '',
    newPassword: '',
    cpassowrd: '',
  });
  const [deleteAccountFormData, setDeleteAccountFormData] = useState({
    password: '',
  });
  const [errors, setErrors]: any = useState({});
  const [success, setSuccess]: any = useState({});
  const hiddenFileInput: any = React.useRef(null);

  useEffect(() => {
    let userBasicInfo: any = localStorage.getItem('userData');
    if (userBasicInfo && userBasicInfo != undefined) {
      userBasicInfo = JSON.parse(userBasicInfo);
      setUserId(userBasicInfo.userId);
    }
  }, []);

  useEffect(() => {
    async function fetchData(userId: string) {
      try {
        let userModuleObj = new quickplay.QuickPlayUserModule();
        let userModuleResponse = await userModuleObj.getUserDetails(userId);
        if (userModuleResponse.status === 200) {
          let userInfo = userModuleResponse.data.result;
          // console.log(userInfo);
          setUserDetail(userInfo);
          setFormData({
            fullName: userInfo?.fullName,
            userName: userInfo?.userName,
            description: userInfo?.description,
            profileImageUrl: userInfo?.profileImageUrl,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData(userId);
  }, [userId]);

  useEffect(() => {
    async function fetchVideos(userId: string) {
      try {
        let userModuleObj = new quickplay.QuickPlayUserModule();
        var userVideoModuleResponse = await userModuleObj.getUserVideoList({
          userId: userId,
        });
        if (userVideoModuleResponse.status === 200) {
          let videoList = userVideoModuleResponse.data.result;
          console.log(videoList);
          setUserVideos(videoList);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchVideos(userId);
  }, [userId]);

  if (!userVideos && !userDetail) {
    return <p>Loading...</p>;
  }
  const toggleMoreOptions = () => {
    setEditMobileSetting(!showMoreOptions);
  };
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleInputChangeForPassword = (event: any) => {
    const { name, value } = event.target;
    setPasswordFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleInputChangeForDeleteAccountPassword = (event: any) => {
    const { name, value } = event.target;
    setDeleteAccountFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const toggleEditProfilePanel = () => {
    setEditProfile(true);
    setShowOverlay(true);
  };

  const toggleEditAccountSettingPanel = () => {
    setEditAccountSetting(true);
    setShowOverlay(true);
  };

  const toggleCloseEditAccountSettingPanel = () => {
    setEditAccountSetting(true);
    setChangePassword(false);
    setDeleteAccount(false);
  };

  const toggleChangePasswordPanel = () => {
    setChangePassword(true);
    setShowOverlay(true);
  };

  const toggleDeleteAccountPanel = () => {
    setDeleteAccount(true);
    setShowOverlay(true);
  };

  const handleCloseEditProfilePanel = () => {
    setEditProfile(false);
    setShowOverlay(false);
  };

  const handleCloseAccountSettingPanel = () => {
    setEditAccountSetting(false);
    setShowOverlay(false);
    setEditMobileSetting(false);
  };

  const handleCloseChangePasswordPanel = () => {
    setEditAccountSetting(false);
    setChangePassword(false);
    setShowOverlay(false);
  };

  const handleCloseSidePanel = () => {
    setEditAccountSetting(false);
    setDeleteAccount(false);
    setShowOverlay(false);
  };

  const userSignout = () => {
    localStorage.removeItem('following');
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    navigate('/sign-in');
  };

  const _logoutUser = () => {
    localStorage.removeItem('following');
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    navigate('/sign-in');
  };

  const updateProfile = async () => {
    var userModuleObj = new quickplay.QuickPlayUserModule();
    var userModuleResponse = await userModuleObj.editUserProfile(formData);
    if (userModuleResponse.status == 200) {
      setSuccess({ sucessMessage: 'Profile has been updated successfully!' });
      setErrors({});
      setTimeout(() => setSuccess({}), 5000);
    } else {
      setSuccess({});
      setErrors({ errorMessage: userModuleResponse?.data?.statusMessage });
      setTimeout(() => setErrors({}), 5000);
    }
  };

  const updatePassword = async () => {
    var userModuleObj = new quickplay.QuickPlayUserModule();
    var userModuleResponse = await userModuleObj.changePassword(
      passwordFormData,
    );
    if (userModuleResponse.status == 200) {
      setPasswordFormData({ oldPassword: '', newPassword: '', cpassowrd: '' });
      setSuccess({
        sucessMessage: 'Your password has been updated successfully!',
      });
      setErrors({});
      setTimeout(() => setSuccess({}), 5000);
    } else {
      setSuccess({});
      setErrors({ errorMessage: userModuleResponse?.data?.statusMessage });
      setTimeout(() => setErrors({}), 5000);
    }
  };

  const handleProfileImageClick = () => {
    hiddenFileInput.current.click();
  };
  const handleFileUploadChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    let toBase64String = await toBase64(fileUploaded);
    var userModuleObj = new quickplay.QuickPlayUserModule();
    var userModuleResponse = await userModuleObj.editUserProfileImage({
      baseString64: toBase64String,
    });
    if (userModuleResponse.status == 200 || userModuleResponse.status == 201) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ['profileImageUrl']: userModuleResponse?.data?.result?.imageUrl,
      }));
      setUserDetail('');
      setUserDetail((prevUserDetail: any) => ({
        ...prevUserDetail,
        ['profileImageUrl']: userModuleResponse?.data?.result?.imageUrl,
      }));
    } else {
      console.log('Something went wrong, please try again');
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleContentDetail = (contentInfo: any) => {
    navigate(`/content/${contentInfo.videoId}`);
  };

  const handleDeleteAccountSubmit = async (event: any) => {
    event.preventDefault();
    try {
      var email = JSON.parse(localStorage.getItem('userData') || "{}")?.email;
      const authLoginInput = new quickplay.QuickPlayAuthModule();
      const signInRawData = {
        email: email,
        password: deleteAccountFormData?.password,
        deviceId: '23424dsffsf',
        deviceType: 'android',
      };
      const userSignInResponse = await authLoginInput.AuthRawData(
        signInRawData,
      );
      console.log('userSignInResponse', userSignInResponse);
      if (userSignInResponse.status === 200) {
        setDeleteAccountModal(true);
      } else {
        deleteAccountFormData.password = '';
        setErrors({
          passwordErrorMessage: userSignInResponse?.data?.statusMessage,
        });
        setTimeout(() => setErrors({}), 5000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDeleteAccount = async () => {
    const userModuleObj = new quickplay.QuickPlayUserModule();
    const deleteProfile = await userModuleObj.deleteUserProfile();
    if (deleteProfile.status == 200) {
      userSignout();
    }
  };
  const goBack = () => {
    window.history.back();
  };

  const handleCloseDeleteAccountModal = () => setDeleteAccountModal(false);

  return (
    <>
      <div className="mobile-nav">
        <a href="javascript:void(0)" className="mobile-nav-toggle" onClick={toggleMoreOptions}>
          <svg xmlns="http://www.w3.org/2000/svg" width="6.302" height="25.21" viewBox="0 0 6.302 25.21">
            <g id="_3_Blue_Dot" data-name="3 Blue Dot" transform="translate(6.302 25.21) rotate(-180)">
              <g id="Group_124" data-name="Group 124" transform="translate(0 0)">
                <path id="Path_117" data-name="Path 117" d="M5.379.923a3.151,3.151,0,1,1-4.456,0,3.151,3.151,0,0,1,4.456,0" transform="translate(0 0)" fill="#FC5933" />
                <path id="Path_118" data-name="Path 118" d="M5.379.923a3.151,3.151,0,1,1-4.456,0,3.151,3.151,0,0,1,4.456,0" transform="translate(0 9.454)" fill="#FC5933" />
                <path id="Path_119" data-name="Path 119" d="M5.379.923a3.151,3.151,0,1,1-4.456,0,3.151,3.151,0,0,1,4.456,0" transform="translate(0 18.907)" fill="#FC5933" />
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div
        className={`side-fixed-panel-inner account-setting ${editMobileSetting ? 'open' : ''
          }`}
      >
        <ul className="panel-header">
          <li>
            <h4>More</h4>
          </li>
          <li className="text-right">
            <a
              href="javascript:void(0)"
              className="popup-close"
              onClick={handleCloseAccountSettingPanel}
            >
              <img src="/quickplay/images/popup-close.svg" />
            </a>
          </li>
        </ul>
        <ul className="drop-menu">
          <li >
            <a
              href="javascript:void(0)"
              className="change-password"
              onClick={toggleChangePasswordPanel}
            >
              Change Password
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </li>
          <li >
            <a
              href="javascript:void(0)"
              className="edit-profile"
              onClick={toggleEditProfilePanel}

            >
              Edit Profile
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              href={`/activity-timeline?tab=like`}
              className="activity-timeline"
            >
              Activity Timeline
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={toggleDeleteAccountPanel}
              className='delete-account'>
              Delete Account
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </li>

        </ul>
        <div className="custom-form-group text-center">
          <input
            onClick={_logoutUser}
            type="submit"
            name=""
            value="Logout"
            style={{
              backgroundColor: 'white',
              color: '#FC5933',
              borderRadius: '10px',
              padding: '10px 50px',
              borderColor: '#FC5933',
              position: "fixed",
              bottom: 0,
              left: "30 %",
            }} />
        </div>
      </div>
      <div className="full-box profile-full-box">
        <div className="profile-page-header">
          <div className="back-btn">
            <a href="javascript:void(0)" className="back-btn-a" onClick={goBack}>
              <img src="/quickplay/images/arrow-small-right.svg" />
              <span>Back</span></a>
          </div>
          <div className="profile-page-header-dropdown web-view">
            <a href="javascript:void(0)" className="profile-page-header-icon">
              <img className="profileImage" src={userDetail?.profileImageUrl} />
            </a>
            <ul className="drop-menu">
              <li>
                <a
                  href="javascript:void(0)"
                  className="edit-profile"
                  onClick={toggleEditProfilePanel}
                >
                  Edit Profile
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="account-setting"
                  onClick={toggleEditAccountSettingPanel}
                >
                  Account Setting
                </a>
              </li>
              <li>
                <a
                  href={`/activity-timeline?tab=like`}
                  className="activity-timeline"
                >
                  Activity Timeline
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="user-sign-out"
                  onClick={userSignout}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-page-head">
          <ul className="profile-page-head-ul list-none">
            <li className="profile-page-head-avatar">
              <div className="img-sec">
                <img
                  src={userDetail?.profileImageUrl}
                  id="profileImage"
                  className="bg-img-02 profileImage"
                />
                <span onClick={toggleEditProfilePanel}>
                  <img
                    src="/quickplay/images/pro-edit.svg"
                    id="editIcon"
                    className="edit-profile"
                  />
                </span>
              </div>
            </li>

            <li className="profile-page-head-content">
              <ul className="profile-page-head-content-inner">
                <li>
                  <h4 id="displayName">{userDetail?.fullName}</h4>
                  <h5 id="userId">{userDetail?.userName}</h5>
                </li>

                <li>
                  <button
                    onClick={() => {
                      setShowFollowModal(true);
                      setFollowModalType('followers');
                    }}
                  >
                    Followers
                  </button>
                  <span id="followerCount">{userDetail?.followersCount}</span>
                </li>
                <li>
                  <button
                    onClick={() => {
                      setShowFollowModal(true);
                      setFollowModalType('following');
                    }}
                  >
                    Following
                  </button>
                  <span id="followingCount">{userDetail?.followingCount}</span>
                </li>

                <li>
                  <button>Posts</button>
                  <span id="videoCount">{userDetail?.videoCount}</span>
                </li>
              </ul>
              <div className="c-text text-blk mt-t-15" id="userDescription">
                {userDetail?.description}
              </div>
            </li>
          </ul>
        </div>
        <FollowModal
          show={showFollowModal}
          onHide={() => setShowFollowModal(false)}
          type={followModalType}
          userData={userDetail}
        />
        <div className="inner-box arrival-box profile-videos">
          <div id="tabs-content">
            <div className="row tab-content userVideoData" id="tab1">
              {userVideos.map((video: any) => {
                let thumbnailUrls = video.thumbnailUrls
                  ? video.thumbnailUrls[0]
                  : video.thumbnailUrl;
                if (video.contentType == 'video') {
                  return (
                    <div
                      className="col-md-3 col-sm-3 col-xs-6 box text-center"
                      key={video.videoId}
                    >
                      <div
                        className="img-box open-video-detail"
                        id={video.videoId}
                        style={{
                          background: `url(${thumbnailUrls}) center`,
                          height: '227px',
                          maxHeight: '227px',
                          borderRadius: '9px',
                          backgroundSize: 'cover',
                        }}
                        onClick={() => handleContentDetail(video)}
                      >
                        <span className="av-icon">
                          <img src="/quickplay/images/folder-icon.svg" />
                        </span>
                      </div>
                    </div>
                  );
                }
                else {
                  return (
                    <div className="col-md-3 col-sm-3 col-xs-6 box text-center">
                      <div className="img-box open-video-detail text-wrapper" style={{
                        background: '#fff',
                        maxHeight: '227px',
                        height: '227px',
                        borderRadius: '9px',
                      }} id={video.videoId}>
                        <span className="av-icon"><img src="../quickplay/images/Text.svg" /></span><p>{video?.description}</p>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="side-fixed-panel">
        <div
          className={`side-fixed-panel-inner edit-profile-section ${editProfile ? 'open' : ''
            }`}
        >
          <ul className="panel-header">
            <li>
              <h4>Edit Profile</h4>
            </li>
            <li className="text-right">
              <a
                href="javascript:void(0)"
                className="popup-close"
                onClick={handleCloseEditProfilePanel}
              >
                <img src="/quickplay/images/popup-close.svg" />
              </a>
            </li>
          </ul>

          <div className="panel-profile">
            <div className="img-sec upload-profile-image">
              <img
                src={userDetail?.profileImageUrl}
                className="profileImage bg-img-02"
              />
              <span onClick={handleProfileImageClick}>
                <img src="/quickplay/images/pro-edit.svg" />
              </span>
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileUploadChange}
              name="profileImage"
              className="uploadProfileImage"
              accept=".jpeg,.jpg,.png"
              style={{ display: 'none' }}
            />
          </div>

          <div className="panel-form profile-panel-form">
            {errors.errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errors.errorMessage}
              </div>
            )}
            {success.sucessMessage && (
              <div className="alert alert-success success-alert" role="alert">
                {success.sucessMessage}
              </div>
            )}

            <div className="custom-form-group first-input-box">
              <label>Full Name*</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>

            <div className="custom-form-group">
              <label>User Name*</label>
              <input
                type="text"
                name="userName"
                value={formData?.userName}
                onChange={handleInputChange}
              />
            </div>

            <div className="custom-form-group">
              <label>About</label>
              <textarea
                rows={4}
                name="description"
                value={formData?.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <input
              type="hidden"
              name="profileImageUrl"
              value={formData?.profileImageUrl}
            />
            <div className="custom-form-group text-center">
              <input
                type="submit"
                name="submit-profile"
                className="update-profile-info"
                value="Save Changes"
                onClick={updateProfile}
              />
            </div>
          </div>
        </div>
        <div
          className={`side-fixed-panel-inner account-setting ${editAccountSetting ? 'open' : ''
            }`}
        >
          <ul className="panel-header">
            <li>
              <h4>Account Setting</h4>
            </li>
            <li className="text-right">
              <a
                href="javascript:void(0)"
                className="popup-close"
                onClick={handleCloseAccountSettingPanel}
              >
                <img src="/quickplay/images/popup-close.svg" />
              </a>
            </li>
          </ul>
          <ul className="drop-menu">
            <li className="active">
              <a
                href="javascript:void(0)"
                className="change-password"
                onClick={toggleChangePasswordPanel}
              >
                Change Password
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="delete-account"
                onClick={toggleDeleteAccountPanel}
              >
                Delete Account
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={`side-fixed-panel-inner change-password-section ${changePassword ? 'open' : ''
            }`}
        >
          <ul className="panel-header">
            <li>
              <h4>
                <span
                  className="act-stng-bckbtn"
                  onClick={toggleCloseEditAccountSettingPanel}
                >
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                Change Password
              </h4>
            </li>
            <li className="text-right">
              <a
                href="javascript:void(0)"
                className="popup-close"
                onClick={handleCloseChangePasswordPanel}
              >
                <img src="/quickplay/images/popup-close.svg" alt="" />
              </a>
            </li>
          </ul>

          <div className="panel-form profile-panel-form">
            {errors.errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errors.errorMessage}
              </div>
            )}
            {success.sucessMessage && (
              <div className="alert alert-success success-alert" role="alert">
                {success.sucessMessage}
              </div>
            )}
            <div className="custom-form-group first-input-box">
              <label>Current Password*</label>
              <input
                type="password"
                name="oldPassword"
                placeholder='Enter Current password'
                value={passwordFormData?.oldPassword}
                onChange={handleInputChangeForPassword}
              />
            </div>

            <div className="custom-form-group">
              <label>New Password*</label>
              <input
                type="password"
                name="newPassword"
                placeholder='Enter New password'
                value={passwordFormData?.newPassword}
                onChange={handleInputChangeForPassword}
              />
            </div>

            <div className="custom-form-group">
              <label>Confirm Password*</label>
              <input
                type="password"
                name="cpassowrd"
                placeholder='Enter New password'
                value={passwordFormData?.cpassowrd}
                onChange={handleInputChangeForPassword}
              />
            </div>

            <div className="custom-form-group text-center">
              <input
                type="submit"
                name=""
                className="update-password"
                value="Save Changes"
                onClick={updatePassword}
              />
            </div>
          </div>
        </div>
        <div
          className={`side-fixed-panel-inner delete-account-section ${deleteAccount ? 'open' : ''
            }`}
        >
          <ul className="panel-header">
            <li>
              <h4>
                <span
                  className="act-stng-bckbtn"
                  onClick={toggleCloseEditAccountSettingPanel}
                >
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                Delete Account
              </h4>
            </li>
            <li className="text-right">
              <a
                href="javascript:void(0)"
                className="popup-close"
                onClick={handleCloseSidePanel}
              >
                <img src="/quickplay/images/popup-close.svg" />
              </a>
            </li>
          </ul>
          <div className="panel-form profile-panel-form">
            <form action="" method="post" onSubmit={handleDeleteAccountSubmit}>
              {errors.passwordErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errors.passwordErrorMessage}
                </div>
              )}
              <div className="custom-form-group first-input-box">
                <label>Password*</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  required
                  value={deleteAccountFormData?.password}
                  onChange={handleInputChangeForDeleteAccountPassword}
                />
                <div className="password-error">Please enter password</div>
                <div className="password-incorrect">
                  Please enter correct password.
                </div>
              </div>
              <div className="custom-form-group text-center">
                <input
                  type="submit"
                  name=""
                  className="delete-account-btn"
                  value="Delete"
                />
              </div>
            </form>
          </div>
        </div>
        <div className={`overlay ${showOverlay ? 'open' : ''}`}></div>
      </div>
      <DeleteAccountModal
        show={showDeleteAccountModal}
        handleClose={handleCloseDeleteAccountModal}
        confirmDelete={handleConfirmDeleteAccount}
      />
    </>
  );
}

export default MyProfile;

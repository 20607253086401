import React, { useEffect, useState } from 'react';
import HeaderSearch from '../components/Layouts/HeaderSearch';
import Sidebar from '../components/Layouts/Sidebar';
import Bottom from '../components/Layouts/Bottom';
import { useNavigate } from 'react-router-dom';
import quickplay from 'quickplay';
import VideoRail from '../components/VideoRail/VideoRail';
import UserRail from '../components/UserRail/UserRail';
import HashtagRail from '../components/HashtagRail/HashtagRail';

const Discover = () => {
  const navigate = useNavigate();
  const [data, setData]:any = useState(null);
  useEffect(() => {
    async function fetchData() {
        try {

            var curatedContentModuleObj = new quickplay.QuickPlayCuratedContentModule();
            var curatedContentModuleResponse =
                await curatedContentModuleObj.getCuratedContentList("discover");
            if (curatedContentModuleResponse.status === 200) {
                setData(curatedContentModuleResponse.data.result||[]);
            }

        } catch (error) {
            console.error(error);
        }
    }
  fetchData();
}, []);

if(data && data.length === 0)
{
  return <p></p>;
}

if (!data) {
  return <p>Loading...</p>;
}

const renderRail = (rail:any) => {
  if (rail.contentType === "video")
    return <VideoRail key={rail.id} data={rail} />
  if (rail.contentType === "user")
  {
    return <UserRail data={rail} />
  }
  if (rail.contentType === "hashtag")
    return <HashtagRail key={rail.id} data={rail} />
};

  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar showProfile={false} />
        </div>
        <div className="right-sec col-md-9 profile-content-list discover-top">
          <HeaderSearch redirect={true} />
          {
            data.map((rail:any)=>(
              // <VideoRail data={rail} />
              renderRail(rail)
            ))
          }
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Discover;

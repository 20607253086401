import React, { useEffect,useState } from 'react';
import Sidebar from "../components/Layouts/Sidebar";
import Profile from "../components/Profile/Profile";



const UserProfile = () => {
  return (
    <div className="full-sec">
      <div className="left-sec col-md-3">
        <Sidebar />
      </div>
      <div className="right-sec col-md-9">
        <Profile />
      </div>
    </div>
  );
};

export default UserProfile;

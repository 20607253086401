import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CommonModal = ({
  id,
  show,
  onHide,
  title,
  titleProps,
  subTitle,
  children,
}:any) => {
  return (
    <Modal show={show} onHide={onHide} id={id}>
      <div className="modal-content text-center">
        {/* <button type="button" className="close" onClick={onHide}>
          &times;
        </button> */}
        <div className="modal-body">
          {title && (
            <h5 className="topHeading" {...titleProps}>
              <strong>{title}</strong>
            </h5>
          )}
          {subTitle && <p>{subTitle}</p>}
          {children}
        </div>
      </div>
    </Modal>
  );
};

CommonModal.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleProps: PropTypes.object,
};

export default CommonModal;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import quickplay from 'quickplay';
import Comment from './Comment';
import LoginModal from '../../Login-UI/LoginModal';
// import LoginModal from '../../PSAW-UI/LoginModal';

const CommentsList = ({ videoId, comments, setComments }: any) => {
  // console.log("check this --->>", comments);
  const inputRef: any = useRef(null);
  const [comment, setComment] = useState('');
  const [commentToUpdate, setCommentToUpdate]: any = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleCreateComment = async (event: any) => {
    event.preventDefault();
    if (!comment) return;

    const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (accessToken == '' || accessToken == undefined || accessToken == null) {
      setShowLoginModal(true);
      return;
    }
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      const userPostedComment =
        await activityTimelineModuleObj.activityTimelineAddComment({
          assetId: videoId,
          description: comment,
          postId: '',
        });
      if (userPostedComment?.data?.success) {
        const commentResponse = userPostedComment?.data?.result;
        const username = JSON.parse(localStorage.getItem('userData') || "{}")?.fullName;
        const profileImageUrl = JSON.parse(
          localStorage.getItem('userData') || "{}"
        )?.profileImageUrl;
        const commentData = {
          ...commentResponse,
          fullName: username,
          profileImageUrl: profileImageUrl,
        };
        setComments([...comments, commentData]);
      }
    } catch (error) {
      console.error(error);
    }
    setComment('');
    // reFetchComments();
  };

  const handleCommentChange = (e: any) => {
    const comment = e.target.value.trim();
    setComment(comment);
  };
  const authVerify = async (e: any) => {
    const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (accessToken) {
      setShowLoginModal(false);
    }
    else {
      setShowLoginModal(true);
    }
  };
  const handleEditComment = async ({ id, refId }: any) => {
    let commentToEdit: any = {};
    if (id) {
      commentToEdit = comments.find(({ postId }: any) => postId === id);
    } else {
      commentToEdit = comments.find(
        ({ commentRefId }: any) => commentRefId === refId,
      );
    }
    // console.log("edit comment-->>", commentToEdit);
    const { description }: any = commentToEdit ?? {};
    setComment(description);
    setCommentToUpdate(commentToEdit);
    inputRef?.current?.focus();
    inputRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleUpdateComment = async ({ event, id, refId }: any) => {
    // event.preventDefault();
    try {
      const feedModuleObj = new quickplay.QuickPlayFeedModule()
      const response = await feedModuleObj.UpdateComment({
        assetId: videoId,
        description: comment,
        ...(id ? { postId: id } : { commentRefId: refId }),
      });
      if (response?.data?.success) {
        const commentResponse = response?.data?.result;
        const username = JSON.parse(localStorage.getItem('userData') || "{}")?.fullName;
        const profileImageUrl = JSON.parse(
          localStorage.getItem('userData') || "{}"
        )?.profileImageUrl;
        const commentData = {
          ...commentResponse,
          fullName: username,
          profileImageUrl: profileImageUrl,
        };
        comments.map((data: any) => {
          if (data.postId == id) {
            data.description = commentResponse.description
          }
        })
        setComments([...comments]);
        setCommentToUpdate(null);
      }
    } catch (error) {
      console.error(error);
    }
    setComment('');
    // reFetchComments();
  };

  const handleDeleteComment = async ({ id, refId }: any) => {
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      const response =
        await activityTimelineModuleObj.activityTimelineDeleteComment({
          assetId: videoId,
          ...(id ? { postId: id } : { commentRefId: refId }),
        });
      if (response.status === 201) {
        const deletedCommentIndex = comments.findIndex(
          ({ postId }: any) => postId === id,
        );
        comments.splice(deletedCommentIndex, 1);
        setComments([...comments]);
      }
    } catch (error) {
      console.error(error);
    }
    // reFetchComments();
  };

  return (
    <>
      {comments?.map((comment: any) => (
        <Comment
          key={comment?.postId}
          videoId={videoId}
          comment={comment}
          handleDeleteComment={handleDeleteComment}
          handleEditComment={handleEditComment}
        />
      ))}
      <div className="creat-comment-strip">
        <div className="creat-comment-box">
          <form
            action="submit"
            onSubmit={event => {
              if (
                commentToUpdate &&
                (commentToUpdate.postId || commentToUpdate.commentRefId)
              ) {
                handleUpdateComment({
                  event,
                  id: commentToUpdate.postId,
                  refId: commentToUpdate.commentRefId,
                });
              } else {
                handleCreateComment(event);
              }
            }}
          >
            <input
              ref={inputRef}
              type="text"
              name="post_comment"
              placeholder="Write a comment"
              value={comment}
              onChange={handleCommentChange}
              onClick={authVerify}
            />
            <a
              href="javascript:void(0);"
              className="comment-submit"
              onClick={event => {
                if (commentToUpdate && commentToUpdate.postId) {
                  handleUpdateComment({ id: commentToUpdate.postId });
                } else {
                  handleCreateComment(event);
                }
              }}
            >
              <img src="/quickplay/images/send-icon.svg" />
            </a>
          </form>
        </div>
      </div>
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
      )}
    </>
  );
};

CommentsList.propTypes = {
  videoId: PropTypes.string,
  comments: PropTypes.array,
  setComments: PropTypes.func,
};

export default CommentsList;

import React from "react";
import quickplay from 'quickplay';
import TopProfiles from '../components/TopProfile/TopProfile';
import FeedData from '../components/Feed';
import Sidebar from '../components/Layouts/Sidebar';
import Bottom from '../components/Layouts/Bottom';
import ProfileSuggestion from '../components/ProfileSuggestion';
function Home() {
  let quickPlaySDKInitilize = new quickplay.QuickPlaySDKInitilize();
  quickPlaySDKInitilize.initialize({
    apiKey: "5bdd28f0-a425-4884-be50-9bf5fa35e319",
    secretKey: "33728ccd-9dec-4bfe-8a66-0c489a1fc8f2",
  });

  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar />
        </div>
        <div className="right-sec col-md-9 profile-content-list">
          <div className="col-md-8 main-feed-wrapper">
            <TopProfiles />
            <FeedData />
          </div>
          <div className="col-md-4 profile-suggestions">
            <ProfileSuggestion />
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
}

export default Home;


import React from 'react';
import Hashtag from '../components/HashtagRail/Hashtag';
import Sidebar from '../components/Layouts/Sidebar';
import Bottom from '../components/Layouts/Bottom';

const HashtagDetail = () => {
  const goBack = () => {
    window.history.back();
  };
  return (
    <>
      <div className="full-sec">
        <div className="left-sec col-md-3">
          <Sidebar />
        </div>
        <div className="right-sec col-md-9 profile-content-list">
          <div className="back-btn hashtags-details">
            <a href="javascript:void(0)" className="back-btn" onClick={goBack}>
              <img src="/quickplay/images/arrow-small-right.svg" />
              <span>Back</span></a>
          </div>
          <Hashtag />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default HashtagDetail;

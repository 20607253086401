import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TopProfile(props:any) {
  const {data} :any = props;
  const [isClient, setIsClient] = useState(true);
 
  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <div className="full-box home-feed-full-box">
      <div className="feed-box">
        <div className="box top-profile-box">
          <div className="top-profile-box-left-sec">
            <div className="rail-header">
              <h4 className="ft-500">{data.railName}</h4>
            </div>
           
              <OwlCarousel
                
                dots={false}
                items={5}
                autoplay={true}
                responsive= {{
                  0: {
                      items: 3,
                      nav: false,
                  },
                  600: {
                      items: 4,
                      nav: false,
                  },
                  1000: {
                      items: 6,
                      loop: false,
                  },
              }}
                className="list list-inline profile-box-slider owl-carousel owl-theme top-profile-list"
              >
                {data?.itemList.map((item:any) => (
                  <li key={item.profile.userId} className="item">
                    <a href={'profile/' + item.profile.userId}>
                      <img src={item.profile.profileImageUrl} />
                      <span className="sml-text name">
                        {item.profile.fullName.length > 10
                          ? item.profile.fullName.substr(0, 10) + '..'
                          : item.profile.fullName}
                      </span>
                      <span className="followers">
                        {item.profile.followersCount} Followers
                      </span>
                    </a>
                  </li>
                ))}
              </OwlCarousel>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopProfile;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const AuthHeader = () => {
  const location = useLocation();
  const isActive = location.pathname;
  return (
    <header>
      <div className="container padding0">
        <div className="col-md-6 col-sm-6 logo-sec">
          <a href="/" className="logo">
            <img src="./quickplay/images/Brand.svg" alt="" style={{ width: '200px', height: '80px' }} />
          </a>
        </div>

        <div className="col-md-6 col-sm-6 text-right sign-ul-parent">
          <ul className="list list-inline sign-ul">
            <li>
              <a href="sign-in"  className={isActive=='/sign-in' ? 'active':''}>Sign In</a>
            </li>
            <li>
              <a href="sign-up"  className={isActive=='/sign-up' ? 'active':''}>
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default AuthHeader;

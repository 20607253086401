import React, { useEffect, useState } from 'react';
import quickplay from 'quickplay'
import { useNavigate } from 'react-router-dom';

function Hashtag() {
    const [hashtagDetail, seHashtagDetail]:any = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const hashTagModuleObj = new quickplay.QuickPlayHashTag();
                let selectedhashTagName = localStorage.getItem("selectedhashTagName");
                let limit = 10;
                let offset = 1;
                const hashTagResponse = await hashTagModuleObj.getHashTagDetails({
                limit: limit,
                offset: offset,
                name: selectedhashTagName,
                });
                if(hashTagResponse?.status === 200)
                    seHashtagDetail(hashTagResponse.data.result);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

  if (!hashtagDetail) {
      return <p>Loading...</p>;
  }

    const handleContentDetail=(contentInfo:any)=>{
        navigate(`/content/${contentInfo.videoId}`);
    }

  return (

      <div className="full-box profile-full-box">
          <div className="profile-page-head">
              <ul className="profile-page-head-ul list-none">
                  <li className="profile-page-head-avatar">
                     
                      <div className="img-sec">
                          <img
                              src={hashtagDetail?.hashtag?.image|| "https://d3ibngdlgwl8mp.cloudfront.net/hashtag/Hashtag.png"}
                              id="profileImage"
                              alt="profile-image"
                              className="bg-img-02 profileImage"
                          />
                      </div>
                  </li>

                  <li className="profile-page-head-content">
                      <ul className="profile-page-head-content-inner">
                          <li>
                              <h4 id="displayName">{hashtagDetail?.hashtag?.title}</h4>
                          </li>

                      </ul>

                  </li>
              </ul>

          </div>

          <div className="inner-box arrival-box profile-videos">
              <div id="tabs-content">
                  <div className="row tab-content userVideoData" id="tab1">
                      {
                        hashtagDetail.videos.map((video:any) => {

                              let thumbnailUrls = video.thumbnailUrls
                                  ? video.thumbnailUrls[0]
                                  : video.thumbnailUrl;
                              return (
                                  <div className="col-md-3 col-sm-3 col-xs-6 box text-center" key={video.videoId}>
                                      <div className="img-box open-video-detail" id={video.videoId}
                                          style={{
                                              "background": `url(${thumbnailUrls}) center`,
                                              "height": "227px",
                                              "maxHeight": "227px",
                                              "borderRadius":"9px",
                                              "backgroundSize":"cover"
                                          }} onClick={() => handleContentDetail(video)}>
                                        <span className="av-icon"><img src="/quickplay/images/folder-icon.svg" /></span>
                                      </div>
                                  </div>
                              )
                          })
                      }
                  </div>
              </div>
          </div>
      </div>
  );


}


export default Hashtag;
import React from 'react';
import { useNavigate } from 'react-router-dom';

function HashtagRail(props:any){
    const navigate = useNavigate();
    const {data}:any = props;

  if (!data) {
    return <p>Loading...</p>;
  }

  const handleHashtagDetail =(hashtagName:any) => {
      localStorage.setItem("selectedhashTagName", hashtagName);
      navigate('/hashtag-detail');
  }


  return (
    
    <div className="inner-box hashtag-box mt-t-45">
        <ul className="head-part">
            <li><h4>{data.railName}</h4></li>
            
        </ul>
        <ul className="hashtag-ul">
            {
                data.itemList.map((hashtagInfo:any, index:number)=> (
                    <li key={index}><a href="javascript:void(0)" className="hashtag-detail" onClick={(e) => handleHashtagDetail(hashtagInfo.hashtag?.title)}># {hashtagInfo.hashtag?.title } </a></li>
                ))
            }
        </ul>

    </div>
      
  );

} 


export default HashtagRail;